import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <section className="content">
      <div className="error-page">
        <div className="error-box">
          <h2 className="headline text-warning text-center"> 404</h2>
          <div className="error-content">
            <h3><i className="fas fa-exclamation-triangle text-warning"></i> 페이지를 찾을수 없습니다.</h3>
            <div className='row'>
              <div className='col text-center pt-3'>
                <Link to="/" className='btn btn-primary'>홈으로</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
