
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { getMessages, cancelMessage } from '@/actions/jbmj';
import _ from 'lodash';
import swal from 'sweetalert';

function MessageList() {
  const dispatch = useDispatch();
  
  const currentUser = useSelector(state => state.user);

  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    dispatch(getMessages(
      currentUser?.user?.id,
      {
        page: currentPage,
      }
    ))
    .then(res => {
      setTotalPageCount(res.total_page_count);
      setTotalItemCount(parseInt(res.total_item_count));
      setCurrentItems(res.item_list);
    })
  }, [currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const onCancelMessage = (message_id) => {
    swal({
      title: "메시지 예약을 취소 하시겠습니까?",
      text: "삭제하시면 복구할수 없습니다.",
      icon: "warning",
      dangerMode: true,
      buttons: ["아니요", "네"],
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch(cancelMessage(currentUser?.user?.id, message_id)).then(res=>{
          const index = _.findIndex(currentItems, {wmmsl_no: message_id});

          currentItems.splice(index, 1, {...currentItems[index], send_type: 3});

          setCurrentItems([...currentItems]);

          swal("예약이 취소되었습니다.", {
            icon: "success",
          });
        })
      }
    }); 
  }

  return (
    <>
      {currentItems && <section className="content pt-3">
        <div className="container new_box_s">
        <div className="box_title font_7">
          발송내역
          <div className="inner_1 font_3">Total<span className="inner_2">{totalItemCount}</span></div>
        </div>        
        <div className="table-responsive">

          <table className="table table-bordered text-center table-hover">
            <colgroup>
                <col width='120px'/>
                <col/>
                <col width='70px'/>
                <col width='70px'/>
                <col width='70px'/>
                <col width='70px'/>
                <col width='70px'/>
                <col width='160px'/>
                {/* <col width="200px"/>
                <col width="90px"/>
                <col width="90px"/>
                <col width="205px"/>
                <col width="105px"/> */}
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th className="align-middle">발송구분</th>
                <th className="align-middle">발송제목</th>
                <th className="align-middle">분류</th>
                <th className="align-middle">대상</th>
                <th className="align-middle">처리중</th>
                <th className="align-middle">성공</th>
                <th className="align-middle">실퍠</th>
                <th className="align-middle">발송일</th>
              </tr>
            </thead>
            <tbody>
            {
          currentItems && currentItems.map((item, index)=>(
          <tr key={item.wmmsl_no}>
                <td>
                  {
                    item.send_type == 1 && '즉시'
                  }
                  {item.send_type == 2 &&  <>
                  {
                    new Date().getTime() < new Date(item.send_datetime).getTime() ? <button type="button" className="btn btn-danger font_6" onClick={()=>onCancelMessage(item.wmmsl_no)}>예약취소</button> : '얘약'
                  }
                  </>}
                  {
                    item.send_type == 3 && '취소'
                  }
                </td>
                <td>
                  {/* <Link to={`/manager/message/${item.id}`}> */}
                    {item.subject}
                  {/* </Link> */}
                </td>
                <td>{item.type}</td>
                <td>{item.step1_count}</td>
                <td>{item.step2_count}</td>
                <td>{item.step3_success_count}</td>
                <td>{item.step3_fail_count}</td>
                <td><Moment format='YYYY-MM-DD hh:mm:ss'>{item.send_datetime}</Moment></td>
              </tr>  
          ))}
            </tbody>
          </table>  
        </div>
          <div className='paging text-center mt-4'>
            <ReactPaginate
              breakLabel='...'
              nextLabel={<span className='paging_end ml30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first mr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName='paging_num paging_current'
              pageLinkClassName='paging_num'
              breakLinkClassName='paging_num'
              previousLinkClassName='paging_num'
              nextLinkClassName='paging_num'
            />
          </div>
        </div>
      </section>}
    </>
  );
}

export default MessageList;
