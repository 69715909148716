
import dashboardService from '@/services/dashboard.service';

export const dashboard = (params) => (dispatch) => {
  return dashboardService.dashboard(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};