import api from '@/services/api';
import queryString from 'query-string';

class ProductService {
  uploadImage(formData) {
    return api
      .post('/products/upload/image', formData, {
        headers: {
         "Content-Type": `multipart/form-data; `,
        }
      })
  }

  martSampleData(params) {
    return api
      .get(`/products/mart/sample-data?${queryString.stringify(params)}`)
  }
}

export default new ProductService();
