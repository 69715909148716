import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { logout } from "@/actions/account";
import MenuItem from '@components/MenuItem';
import { updateAccountApiKey, getAccountInfo } from '@/actions/jbmj';
import history from '@/helpers/history';

export const MENU = [
  {
    name: '모바일 전단',
    icon: 'gnb_icon icon1',
    children: [
      {
        name: '모바일 전단 목록',
        path: '/manager/leaflet/list',
        icon: 'gnb_icon depth2',
      },
      {
        name: '모바일 전단 만들기',
        path: '/manager/leaflet/create',
        icon: 'gnb_icon depth2',
      },
    ]
  },
  {
    name: '메시지 발송',
    path: '/manager/message/create',
    icon: 'gnb_icon icon2',
  },
  {
    name: '발송내역',
    path: '/manager/message/list',
    icon: 'gnb_icon icon3',
  },
  {
    name: '주문내역',
    path: '/manager/order/list',
    icon: 'gnb_icon icon4',
  },
  {
    name: '고객센터',
    icon: 'gnb_icon icon5',
    children: [
      {
        name: '공지사항',
        path: '/manager/support/noti/list',
        icon: 'gnb_icon depth2',
      },
      {
        name: '문의하기',
        path: '/manager/support/qa/list',
        icon: 'gnb_icon depth2',
      },
    ]
  },
  {
    name: '설정',
    path: '/manager/settings',
    icon: 'gnb_icon icon4',
  },
];

function NavigationBar(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user);
  const menuItemFlat = useSelector((state) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state) => state.ui.menuChildIndent);
  const [jbmjAccount, setJBMJAccount] = useState(null);

  useEffect(()=>{
    if(currentUser.user.isConnectedJBMJ){
      dispatch(getAccountInfo(currentUser.user.id))
      .then((res) => {
        setJBMJAccount(res)
      })
    }
  }, [history.location, currentUser.user])

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/manager" className="brand-link">
          <img
            src="/logo.png"
            alt="직방문자"
            className="brand-image img-circle elevation-3"
            style={{opacity: '.8'}}
          />
          <span className="brand-text font-weight-light">직방문자</span>
        </Link>
        {currentUser?.user?.isConnectedJBMJ && jbmjAccount && <div className="gnb_slide_container">
          <div className="my_fare">
            <div className="inner1"><span className="inner_span font_6">나의 잔여요금 &nbsp;</span></div>
            <div className="inner2 font_7">{jbmjAccount.my_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span className="inner_span font_5">원</span></div>
          </div>
          <div className="my_fare_box">
            <ul className="inner_ul">
              <li className="font_4">단문자(SMS)</li>
              <li>{Math.floor(jbmjAccount.my_point/jbmjAccount.require_send_point.sms).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</li>
            </ul>
            <ul className="inner_ul">
              <li className="font_4">장문자(LMS)</li>
              <li>{Math.floor(jbmjAccount.my_point/jbmjAccount.require_send_point.lms).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</li>
            </ul>
            <div className="my_fare_charge">
              <a href="https://www.jbmjw.co.kr/p1100.php" className="btn_red font_7 btn_style" target='_blank'>포인트 충전</a>
            </div>
          </div>
        </div>}
        <div className="sidebar">
          <nav className="mt-2" style={{overflow: 'hidden'}}>
            <ul
              className={`nav nav-pills nav-sidebar flex-column${
                menuItemFlat ? ' nav-flat' : ''
              }${menuChildIndent ? ' nav-child-indent' : ''}`}
              role="menu"
            >
              {MENU.map((menuItem) => (
                <MenuItem key={menuItem.name} menuItem={menuItem} />
              ))}
            </ul>
          </nav>
        </div>
        <div className="service_Manual">
            <Link to="" className="btn_black font_7 btn_style">서비스 이용 메뉴얼</Link>
          </div>
      </aside>
    </>
  );
}

export default NavigationBar;
