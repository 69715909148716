
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import { questions } from '@/actions/board';
import _ from 'lodash';

function QuestionList() {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.user);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    setCurrentItems(null);
    dispatch(questions(
      {
        page: currentPage,
      }
    ))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }, [currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      {currentItems && <section className="content pt-3">
        <div className="container new_box_s">
        <div className="box_title font_7">
          문의하기
          <div className="inner_1 font_3">
            Total<span className="inner_2">{totalItemCount}</span>
            <Link to={`/manager/support/qa/write`} className='btn btn-success font_6' style={{width: '150px', marginLeft:'10px', marginTop: '-7px'}}>
              문의하기
            </Link>
          </div>
        </div>        
        <div className="table-responsive">

          <table className="table table-bordered text-center table-hover">
            <colgroup>
                <col width='100px'/>
                <col/>
                {currentUser.user.role === 'Admin' && <col width='100px'/>}
                <col width='100px'/>
                <col width='200px'/>
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th className="align-middle">순번</th>
                <th className="align-middle">제목</th>
                {currentUser.user.role === 'Admin' && <th className="align-middle">아이디</th>}
                <th className="align-middle">상태</th>
                <th className="align-middle">작성일</th>
              </tr>
            </thead>
            <tbody>
            {
              currentItems && currentItems.map((item, index)=>
              <tr key={index}>
                <td>{totalItemCount - (currentPage - 1) * 10 - index}</td>
                <td>
                  <Link to={`/manager/support/qa/view/${item._id}`}>
                    {item.title}
                  </Link>
                </td>
                {currentUser.user.role === 'Admin' && <td>{item.account.user_id}</td>}
                <td>{item.comment ? '답변완료':'답변대기'}</td>
                <td><Moment format='YYYY-MM-DD hh:mm:ss'>{item.send_datetime}</Moment></td>
              </tr>  
            )}
            </tbody>
          </table>  
        </div>
          <div className='paging text-center mt-4'>
            <ReactPaginate
              breakLabel='...'
              nextLabel={<span className='paging_end ml30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first mr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName='paging_num paging_current'
              pageLinkClassName='paging_num'
              breakLinkClassName='paging_num'
              previousLinkClassName='paging_num'
              nextLinkClassName='paging_num'
            />
          </div>
        </div>
      </section>}
    </>
  );
}

export default QuestionList;
