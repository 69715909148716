import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Map, MapMarker, CustomOverlayMap} from 'react-kakao-maps-sdk';
import { useSelector } from 'react-redux';

export default function Store() {
  const [level, setLevel] = useState(1)
  console.log('level', level)
  const { leaflet } = useSelector((state) => state.leaflet);

  return (
    <>
    <div className='row'>
      <div className='col-12 mt-3'>
        <h5>매장</h5>
      </div>
    </div>
    {(leaflet?.shop?.address_map && leaflet?.shop?.address_map?.x > 0 && leaflet?.shop?.address_map?.y) ? <div className='row'>
      <div className='col-12 mb-2'>
        {!leaflet?.shop?.image ? <Map
          center={{
            lat: leaflet?.shop?.address_map?.y,
            lng: leaflet?.shop?.address_map?.x
          }}
          style={{
            width: "100%",
            height: "450px",
          }}
          level={3}
        >
          <MapMarker
            position={{
              lat: leaflet?.shop?.address_map?.y,
              lng: leaflet?.shop?.address_map?.x
            }}
          />
        </Map> : <Map
          center={{
            lat: leaflet?.shop?.address_map?.y,
            lng: leaflet?.shop?.address_map?.x
          }}
          style={{
            width: "100%",
            height: "450px",
          }}
          onZoomChanged={(map) => setLevel(map.getLevel())}
          level={level}
        >
          <CustomOverlayMap
            position={{
              lat: leaflet?.shop?.address_map?.y,
              lng: leaflet?.shop?.address_map?.x
            }}
            xAnchor={0.5}
            yAnchor={1-0.000001*level}
          >
            <div className="overlaybox" style={{marginTop:`${-3 * level}px`}}>
              <div className="image_box" style={{backgroundImage: `url('${leaflet?.shop?.image}'), url(/images/no-image.jpg)`, backgroundSize: 'no-repeat'}}>
                {leaflet?.shop?.name && <div className="text">{leaflet?.shop?.name}</div>}
              </div>
            </div>
          </CustomOverlayMap>
        </Map>}
      </div>
    </div> : leaflet?.shop?.image ? <img src={leaflet?.shop?.image} onError={e=>e.target.src='/images/no-image.jpg'} className="w-100"/> : <></>}
    <div className="card mb-2">
      <div className="card-body">
        {!(leaflet?.shop?.address_map && leaflet?.shop?.address_map?.x > 0 && leaflet?.shop?.address_map?.y) && leaflet?.shop?.name && <div>
          <label>상호명</label>
          <p>{leaflet?.shop?.name}</p>
        </div>}
        {leaflet?.shop?.opening_hour && <div>
          <label>운영시간</label>
          <p>{leaflet?.shop?.opening_hour}</p>
        </div>}  
        {leaflet?.shop?.holiday && <div className="form-group">
          <label>휴무일</label>
          <p>{leaflet?.shop?.holiday}</p>
        </div>}
        {leaflet?.shop?.tel && <div className="form-group">
          <label>전화번호</label>
          <p><a href={`tel:${leaflet?.shop?.tel}`}>{leaflet?.shop?.tel}</a></p>
        </div>}
        {leaflet?.shop?.address && <div className="form-group">
          <label>주소</label>
          <p>{leaflet?.shop?.address}</p>
        </div>}
        {leaflet?.shop?.introdution && <div className="form-group">
          <label>소개글</label>
          <p>{leaflet?.shop?.introdution}</p>
        </div>}
      </div>
    </div>
    </>
  );
}
