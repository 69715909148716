import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="gnb">
      <div className='gnb_container d-flex justify-content-center align-items-center'>
        <img src="/images/logo.png" alt="메인" className="logo_img"/>
      </div>
    </header>
  );
}
