
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { notice, deleteNotice } from '@/actions/board';
import _ from 'lodash';
import history from '@/helpers/history';
import dompurify from 'dompurify';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import config from '@/config';

function NoticeView() {
  const dispatch = useDispatch();
  const params = useParams();
  const sanitizer = dompurify.sanitize;

  const currentUser = useSelector(state => state.user);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(notice(params.notice_id))
    .then(res => {
      setCurrentItem(res);
    })
  }, []);

  const onDeleteNotice = (event) => {
    Swal.fire({
      text: '삭제 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteNotice(params.notice_id))
        .then(res => {
          toast.success('삭제 되었습니다..', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
          history.navigate('/manager/support/noti/list', {replace: true});
        })
      }
    })
  };

  const bytesToSize = (bytes) => { // 1
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  return (
    <>
      {currentItem && <section className="content pt-3">
        <div className="container new_box_s">
        <div className="box_title font_7">
          {currentItem.title}
          <div className="inner_1 font_3">
              {currentUser.user.role === 'Admin' && <>
              <button type="button" className="btn btn-danger font_6" onClick={()=>onDeleteNotice()}>삭제</button>
                <button type="button" className="btn btn-success font_6" onClick={()=>history.navigate(`/manager/support/noti/edit/${params.notice_id}`, {replace: true})} style={{marginLeft: '5px'}}>수정</button>
              </>}
              <button type="button" className="btn btn-success font_6" onClick={()=>history.navigate('/manager/support/noti/list', {replace: true})} style={{marginLeft: '5px'}}>목록</button>
            </div>
          </div>        
          <div dangerouslySetInnerHTML={{__html: sanitizer(currentItem.body)}} />
        </div>
       {currentItem.attachment&&<div>
          <h3>첨부파일</h3>
          <Link to={`${config.API_SERVER_HOST}/boards/attachment/download/${currentItem.attachment.server_filename}`} target="_blank" download>{currentItem.attachment.original_filename}</Link>&nbsp;({bytesToSize(currentItem.attachment.size)})
        </div>}
      </section>}
    </>
  );
}

export default NoticeView;
