import { SET_BASE_PATH, CLEAR_BASE_PATH, SET_IS_LOADING, SET_IS_SHOW_MODAL_BACKDROP } from './types';

export const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: isLoading,
})

export const setIsShowModalBackdrop = (isShowModalBackdrop) => ({
  type: SET_IS_SHOW_MODAL_BACKDROP,
  payload: isShowModalBackdrop,
})

export const setBasePath = (path) => ({
  type: SET_BASE_PATH,
  payload: path,
})

export const clearBasePath = () => ({
  type: CLEAR_BASE_PATH,
})
