import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
      <footer className="main-footer">
        <strong>
          <span>Copyright © 2022 </span>
          <a href="https://martshop.co.kr" target="_blank" rel="noopener noreferrer">
            martshop.co.kr
          </a>
          <span>.</span>
        </strong>
      </footer>
    </>
  );
}

export default Footer;
