import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeOneItem, removeItem } from '@/actions/cart';

export default function Cart() {
  const dispatch = useDispatch();
  const params = useParams();

  const { leaflet } = useSelector((state) => state.leaflet);
  const { items } = useSelector((state) => state.cart);
  const cartTotal = items
    .map((item) => item.price * item.qty)
    .reduce((prev, current) => prev + current, 0);

  return (
    <>
      <div className='row'>
        <div className='col-12 mt-3 mb-2'>
          <h5>장바구니</h5>
        </div>
      </div>
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12">
            {items.length > 0 ? items.map(item=><div className="card rounded-3 mb-2">
              <div className="card-body p-2">
                <div className="row d-flex justify-content-between align-items-center">
                  <div className="col-2">
                    {item.image ? <img
                      src={item.image}
                      onError={e=>e.target.src='/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} /> : <img
                      src={'/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} />}
                  </div>
                  <div className="col-5">
                    <p className="bold mb-2">{item.name}</p>
                    {item.spec && <p><span className="text-muted">{item.spec}</span></p>}
                  </div>
                  <div className="col-3 text-center">
                    <h5 className="mb-1">{(item.price * item.qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
                    <div className="d-flex justify-content-center align-items-center">
                      <button className="btn btn-sm btn-primary" onClick={()=>dispatch(removeOneItem(item._id))}>
                        <i className="fas fa-minus"></i>
                      </button>

                      {/* <input id="form1" min="0" name="quantity" value= type="number" className="form-control form-control-sm" /> */}
                      <div className='text-center' style={{lineHeight: '33.5px', width:'30px'}}>{item.qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>

                      <button className="btn btn-sm btn-primary" onClick={()=>dispatch(addItem(item))}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-2 text-center">
                    <button className="btn btn-danger btn-sm" onClick={()=>dispatch(removeItem(item._id))}><i className="fas fa-trash fa-lg"></i></button>
                  </div>
                </div>
              </div>
            </div>) : (
              <div className="card rounded-3 mb-2" style={{height: '80px'}}>
                <div className="card-body p-2">
                  <div className="row d-flex justify-content-center align-items-center" style={{height: '100%'}}>
                    장바구니가 비어 있습니다.
                  </div>
                </div>
              </div>
            )}

            {items.length > 0 && <><div className="card mb-2">
              <div className="card-body">
                <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
                  <dt>상품금액</dt>  
                  <dd><strong className='d_price'>{cartTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
                </dl>
                <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
                  <dt>배송비</dt>  
                  <dd><strong className='d_price'>{
                    leaflet?.shop ? cartTotal >= leaflet.shop.delivery_fee_free_amt ? '무료배송' : leaflet.shop.delivery_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): '무료배송'
                  }</strong></dd>
                </dl>
                <dl className='d-flex justify-content-between align-items-center w-100 mb-0'>
                  <dt>합계</dt>  
                  <dd><strong className='fc_red t_price'>{(cartTotal+(cartTotal >= (leaflet?.shop?.delivery_fee_free_amt || 0) ? 0 : (leaflet?.shop?.delivery_fee || 0))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
                </dl>
              </div>
            </div>
            
            {leaflet?.shop && <div className='row'>
                <div className='col-12 mb-3 text-center'>
                  {leaflet.shop.delivery_fee_free_amt > 0 && <> {leaflet.shop.delivery_fee_free_amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 이상 주문시 무료배송</>}
                </div>
            </div>}

            <div className="row">
              <div className="col-12">
                  {cartTotal>=(leaflet?.shop?.min_sale_amt || 0) ? <Link to={`/shop/cart/${params.leaflet_id}/order`} className="btn btn-primary btn-block btn-lg" disabled>주문서 작성</Link> : <button className="btn btn-primary btn-block btn-lg" disabled>
                    주문서 작성 <br /> <span style={{fontSize:'15px'}}>(최소 주문금액 : {(leaflet?.shop?.min_sale_amt || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 이상</span>)
                  </button>}
              </div>
            </div></>}
          </div>
        </div>
    </>
  );
}
