import { ADD_TO_CART, REMOVE_ONE_FROM_CART, REMOVE_FROM_CART, CLEAR_CART } from '@/actions/types';

let initialState = {
  items: []
};

export default function cart(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART:
      const id = payload.cartItem._id;
      const oldItem = state.items.find(item=>item._id === id);
      
      let cartItems = null;

      if (oldItem) {
        oldItem.qty++;
        cartItems = [...state.items];
      } else {
        cartItems = [...state.items, { ...payload.cartItem, qty: 1 }];
      }

      return {
        items: cartItems
      };
    case REMOVE_ONE_FROM_CART:      
      const item = state.items.find(item=>item._id === payload.cartItemId);

      if (item) {
        item.qty--;
        if(item.qty <= 0){
          return {
            ...state,
            items: state.items.filter(
              (item) => item._id !== payload.cartItemId
            )
          };
        } else {
          return {
            items: [...state.items]
          }
        }
      }
    case REMOVE_FROM_CART:
      return {
        items: state.items.filter(
          (item) => item._id !== payload.cartItemId
        )
      };

    case CLEAR_CART:
      return initialState;

    default:
      return state;
  }
}