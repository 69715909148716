import history from '@/helpers/history';

import {
  UPDATE_USER_INFO,
  SET_ACCESS_TOKEN,
  SET_CURRENT_USER,
} from './types'

import AuthService from '@/services/auth.service';

const JWT_EXPIRY_TIME = 30 * 60 * 1000; // 만료 시간

export const signup = (params) => (dispatch) => {
  return AuthService.register(params)
    .then((response)=>{
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: SET_CURRENT_USER,
          payload: {}
        })

        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: ''
        })

      return Promise.reject(message);
    }
  );
};

export const login = (user_id, password) => (dispatch) => {
  return AuthService.login(user_id, password)
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      dispatch({
        type: SET_CURRENT_USER,
        payload: {}
      })
      
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: ''
      })

      return Promise.reject(message);
    }
  );
};

export const forgotPassword = (userId, emailDomain) => (dispatch) => {
  return AuthService.forgotPassword({userId, emailDomain})
    .then(response => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
      (error.response &&
       error.response.data &&
       error.response.data.message) ||
       error.message ||
       error.toString();

      return Promise.reject(message);
    }
  );
}

export const refreshToken = () => (dispatch, selector) => {
  if(selector((state) => state)?.account?.accessToken) {
    return AuthService.refreshToken()
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);
    },
    (error) => {
      if(error.response.status === 400 && error.response.data.message === 'Invalid token'){
        dispatch(logout()).then(() => history.navigate('/',{replace: true}));
      }
    }
  );    
  }
}

export const user = (user_id) => (dispatch, selector) => {
  return AuthService.user(user_id)
    .then(response => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const me = () => (dispatch, selector) => {
  return AuthService.me()
    .then(response => {

      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const updateAccount = (userId, params) => (dispatch) => {
  return AuthService.updateAccount(userId, params)
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const updateAccountShop = (userId, params) => (dispatch) => {
  return AuthService.updateAccountShop(userId, params)
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const allUsers = (params) => (dispatch) => {
  return AuthService.allUsers(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const resendVerifyEmail = (token) => (dispatch) => {
  return AuthService.resendVerifyEmail(token)
    .then((response)=>{
      if(response.data.isVerified){
        dispatch({
          type: UPDATE_USER_INFO,
          payload: {       
            isVerified: response.data.isVerified,
          },
        });
      }
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const existsUserId = (userId) => (dispatch) => {
  return AuthService.existsUserId(userId)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const existsMobile = (mobile_number) => (dispatch) => {
  return AuthService.existsMobile(mobile_number)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const verifyEmail = (token) => (dispatch) => {
  return AuthService.verifyEmail(token)
    .then((response)=>{
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      dispatch(logout()).then(() => history.navigate('/',{replace: true}));

      return Promise.reject(message);
    }
  );
};

export const logout = () => (dispatch) => {
  dispatch(setCurrentUser({}));
  dispatch(setAccessToken(''));

  return Promise.resolve({message: 'logout successfully'});
}

export const setCurrentUser = (currentUser = {}) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: currentUser
  })
}

export const setAccessToken = (accessToken) => (dispatch) => {
  localStorage.setItem('accessToken', accessToken);

  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: accessToken
  })
}
