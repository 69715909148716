import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setIsMenuSidebarCollapsed} from '@/actions/ui';
import { logout } from "@/actions/account";
import history from '@/helpers/history';

function Header() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user);

  const menuSidebarCollapsed = useSelector(
    (state) => state.ui.isMenuSidebarCollapsed
  );
  
  const handleToggleMenuSidebar = () => {
    dispatch(setIsMenuSidebarCollapsed(!menuSidebarCollapsed));
  };

  return (
    <>
      <nav className="main-header navbar navbar-expand">
        <ul className="navbar-nav">
          <li className="nav-item">
              <button
                onClick={handleToggleMenuSidebar}
                type="button"
                className="nav-link"
              >
                <i className="fas fa-bars" />
              </button>
          </li>
        </ul> 
        <ul className="navbar-nav ml-auto mr-3">
          <li className="nav-item">
            {currentUser?.user?.shop?.name && <>
            <span className="h_name">{currentUser?.user?.shop?.name}</span>
            <span className="w_space"></span></>}
            {currentUser?.user?.shop?.name && <>
            <span className="h_name">{currentUser?.user?.name}</span>
            <span className="w_space"></span>
            </>}
            <span className="h_name">{currentUser?.user?.user_id}</span>
            <button type="button" className="btn_black font_7 btn_style btn_min" onClick={()=>history.navigate('/manager/settings', {replace: true})}>매장정보</button>
            <button type="button" className="btn_red font_7 btn_style btn_min" onClick={()=>dispatch(logout())}>로그아웃</button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Header;
