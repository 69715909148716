import { SET_CURRENT_LEAFLET } from '@/actions/types';

let initialState = {
  leaflet: null
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_LEAFLET:
      return {
        leaflet: payload
      };
    default:
      return state;
  }
}