import React, { useState, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import history from '@/helpers/history';
import Header from './Header';
import Gnb from './Gnb';

import { leaflet } from '@/actions/leaflet';

export default function Index() {
  const dispatch = useDispatch();
  const params = useParams();


  useEffect(() => {
    dispatch(leaflet(params.leaflet_id))
    .then((res) => {

    })
    .catch(message => {
      history.navigate('/not-found', {replace: true})
    });
  }, []);

  return (
    <>
      <div className='shop_container'>
        <Header />
        <Outlet />
        <Gnb />
      </div>
    </>
  );
}

