const bannerData = {
  delivery: {
    name: '배송',
    item_count: 5
  },
  fresh:{
    name: '신선',
    item_count: 8
  },
  fresh:{
    name: '핫딜',
    item_count: 8
  },
  meat:{
    name: '육류',
    item_count: 3
  },
  timesale:{
    name: '기간한정',
    item_count: 7
  },
  weekendsale:{
    name: '주말한정',
    item_count: 8
  },
  etc:{
    name: '기타',
    item_count: 7
  },
}

export default bannerData;