import { SET_IS_SHOW_MODAL_BACKDROP, SET_IS_MENU_SIDEBAR_COLLAPSED, SET_SCREEN_SIZE } from './types';

export const setIsShowModalBackdrop = (isShowModalBackdrop) => ({
  type: SET_IS_SHOW_MODAL_BACKDROP,
  payload: isShowModalBackdrop,
})

export const setIsMenuSidebarCollapsed = (isMenuSidebarCollapsed) => ({
  type: SET_IS_MENU_SIDEBAR_COLLAPSED,
  payload: isMenuSidebarCollapsed,
})

export const setScreenSize = (screenSize) => ({
  type: SET_SCREEN_SIZE,
  payload: screenSize,
})



