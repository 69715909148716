const cornerData = [{
  text: '야채코너',
  image: '/images/section_title/1.jpg'
},{
  text: '과일코너',
  image: '/images/section_title/4.jpg'
},{
  text: '정육코너',
  image: '/images/section_title/6.jpg'
},{
  text: '수산코너',
  image: '/images/section_title/9.jpg'
},{
  text: '공산코너',
  image: '/images/section_title/10.jpg'
},{
  text: '생활용품',
  image: '/images/section_title/11.png'
},{
  text: '베이커리',
  image: '/images/section_title/12.png'
},{
  text: '냉동코너',
  image: '/images/section_title/15.jpg'
},{
  text: '식품코너',
  image: '/images/section_title/18.jpg'
},{
  text: '비식품코너',
  image: '/images/section_title/17.jpg'
},{
  text: '식자재',
  image: '/images/section_title/20.jpg'
}];

export default cornerData;