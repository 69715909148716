
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import moment from 'moment'
import ContentHeader from '@/components/ContentHeader';
import { leaflets, deleteLeaflet } from '@/actions/leaflet';
import _ from 'lodash';
import swal from 'sweetalert';

function LeafletList() {
  const dispatch = useDispatch();
  
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [isLeafletDelete, setIsLeafletDelete] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch(leaflets({
      page: currentPage,
    }))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }, [currentPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const deleteLeafletItem = (leaflet_id) => {
    swal({
      title: "삭제 하시겠습니까?",
      text: "삭제하시면 복구할수 없습니다.",
      icon: "warning",
      dangerMode: true,
      buttons: ["아니요", "네"],
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch(deleteLeaflet(leaflet_id)).then(res=>{
          setCurrentItems(_.filter(currentItems, row => row.id != leaflet_id));
          swal("삭제되었습니다.", {
            icon: "success",
          });
        })
      }
    }); 
  }

  const getDayName = (day) => {
    let name = ''
    switch (day) {
      case 0:
        name = '일요일'
        break;
      case 1:
        name = '월요일'
        break;
      case 2:
        name = '화요일'
        break;
      case 3:
        name = '수요일'
        break;
      case 4:
        name = '목요일'
        break;
      case 5:
        name = '금요일'
        break;
      case 6:
        name = '토요일'
        break;
      default:
        break;
    }
    return name;
  }

  return (
    <>
      <section className="content pt-3">
        <div className="container new_box">
        <div className="box_title font_7">
          모바일 전단지
          <div className="inner_1 font_3">Total<span className="inner_2">{totalItemCount}</span></div>
        </div>
        <div className="table-responsive">

          <table className="table table-bordered text-center table-hover">
            <colgroup>
                <col width="80px"/>
                <col width="170px"/>
                <col width=""/>
                <col width="118px"/>
                <col width="86px"/>
                <col width="86px"/>
                <col width="118px"/>
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th className="align-middle">NO</th>
                <th className="align-middle">등록일</th>
                <th className="align-middle">제목</th>
                <th className="align-middle">미리보기</th>
                <th className="align-middle">수정</th>
                <th className="align-middle">삭제</th>
                <th className="align-middle">문자</th>
              </tr>
            </thead>
            <tbody>
            {
          currentItems && currentItems.map((item, index)=>(
          <tr key={item.id}>
                <td>{totalItemCount - (currentPage - 1) * 10 - index}</td>
                <td><Moment format='YYYY-MM-DD'>{item.created}</Moment> <span>[<Moment format='HH:mm'>{item.created}</Moment>]</span></td>
                <td className="table_title">
                  <div  className="inner1">{item.title}</div>
                  {/* <div  className="inner2">행사기간: <Moment format='YYYY-MM-DD'>{item.from_date}</Moment> ~ <Moment format='YYYY-MM-DD'>{item.to_date}</Moment></div> */}
                  <div  className="inner2">행사기간: <Moment format='YYYY-MM-DD'>{item.from_date}</Moment> {getDayName(moment(item.from_date).day())} {moment(item.from_date).format('HH:mm') !== '00:00' && <span className="f_blue">[<Moment format='HH:mm'>{item.from_date}</Moment>]</span>}  ~  <Moment format='YYYY-MM-DD'>{item.to_date}</Moment> {getDayName(moment(item.to_date).day())} {moment(item.from_date).format('HH:mm') !== '00:00' && <span className="f_blue">[<Moment format='HH:mm'>{item.to_date}</Moment>]</span>} {(Math.round(Math.abs((new Date(item.to_date) - new Date(item.from_date)) / (24 * 60 * 60 * 1000))))>0 ? <>( {Math.round(Math.abs((new Date(item.to_date) - new Date(item.from_date)) / (24 * 60 * 60 * 1000)))}일 간 )</> : '하루만'}</div>
                </td>
                <td><a href={`/shop/${item.id}`} target="_blank" className='btn_style btn_basic btn_min' rel="noreferrer">미리보기</a></td>
                <td><Link to={`/manager/leaflet/${item.id}/modify`} className='btn_style btn_red btn_min_x'>수정</Link></td>
                <td><button className='btn_style btn_red btn_min_x' onClick={()=>deleteLeafletItem(item.id)}>삭제</button></td>
                <td><Link to={`/manager/message/create?leaflet_id=${item.id}`} className='btn_style btn_basic btn_min'>보내기</Link></td>
              </tr>  
          ))}
          {
            currentItems && currentItems.length == 0 && <tr>
              <td colSpan={7}>전단지 목록이 없습니다.</td>
            </tr>
          }
            </tbody>
          </table>  
        </div>
          <div className='paging text-center mt-4'>
            <ReactPaginate
              breakLabel='...'
              nextLabel={<span className='paging_end ml30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first mr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName='paging_num paging_current'
              pageLinkClassName='paging_num'
              breakLinkClassName='paging_num'
              previousLinkClassName='paging_num'
              nextLinkClassName='paging_num'
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default LeafletList;
