import React, {useState, useEffect, useCallback} from 'react';
import { Outlet } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Header from '@/screens/Manager/Header';
import Footer from '@/screens/Manager/Footer';
import {setIsMenuSidebarCollapsed} from '@/actions/ui';
import NavigationBar from '@/screens/Manager/NavigationBar';
import {addWindowClass, removeWindowClass, sleep} from '@/helper';

export default function Manager() {
  const dispatch = useDispatch();

  const menuSidebarCollapsed = useSelector(
    (state) => state.ui.isMenuSidebarCollapsed
  );
  
  const screenSize = useSelector((state) => state.ui.screenSize);

  const handleToggleMenuSidebar = () => {
    dispatch(setIsMenuSidebarCollapsed(!menuSidebarCollapsed));
  };

  useEffect(() => {
    removeWindowClass('register-page');
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, []);
  
  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && (screenSize === 'xs' || screenSize === 'sm')) {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  return (
    <div className="wrapper">
      <Header />
      <NavigationBar />
        <div className="content-wrapper">
          <Outlet />
        </div>
      {/* <Footer /> */}
      <div
        id="sidebar-overlay"
        role="presentation"
        onClick={handleToggleMenuSidebar}
        onKeyDown={() => {}}
      />
    </div>
  );
}
