import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <></>
    // <div className='footer_wrap home mt-1'>
    //   <div className="footer_top_area">
    //     <a href="/bbs/term.php?gubun=terms">서비스 이용약관</a>
    //     &nbsp;&nbsp;<span>l</span>&nbsp;&nbsp;
    //     <a href="/bbs/term.php?gubun=privacy">개인정보 처리방침 및 수집 이용동의</a>
    //   </div>
    //     상호: (주)칼라애드 &nbsp;│&nbsp; 주소: 광주광역시 동구 학소로 139, 3층(학동)<br />
    //     대표: 조원주 &nbsp;│&nbsp; 사업자등록번호: 369-88-01037 &nbsp;│&nbsp; Tel. 062-228-9200<br />
    //     개인정보 보호책임자: 김세진   
    // </div>
  );
}
