import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Leaflet/Header';
import { order } from '@/actions/order';

export default function OrderConfirm() {
  const dispatch = useDispatch();
  const params = useParams();

  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    console.log('order_id', params.order_id)
    dispatch(order(params.order_id))
    .then((res) => {
      setCurrentItem(res);
    })
    .catch(message => {
      // history.navigate('/manager/leaflet/list', {replace: true})
    });
  }, []);

  return (
    <>
    {currentItem && <div className='shop_container'>
      <Header />
      <div className='row'>
        <div className='col-12 mt-3 mb-2'>
          <h5>주문완료</h5>
        </div>
      </div>
      <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12">
        {currentItem.cart.map(item=><div className="card rounded-3 mb-2">
          <div className="card-body p-2">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-2">
                  {item.image ? <img
                      src={item.image}
                      onError={e=>e.target.src='/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} /> : <img
                      src={'/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} />}
              </div>
              <div className="col-5">
                <p className="mb-2 bold ">{item.name}</p>
                <p><span className="text-muted">{item.spec}</span></p>
              </div>
              <div className="col-2 d-flex text-center">
                <h5 className="mb-0">{item.qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
              </div>
              <div className="col-3 text-center">
                <h5 className="mb-0">{(item.qty * item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
              </div>
            </div>
          </div>
        </div>)}

        <div className="card mb-2">
          <div className="card-body">
            <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
              <dt>상품금액</dt>  
              <dd><strong className='d_price'>{currentItem?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
            </dl>
            <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
              <dt>배송비</dt>  
              <dd><strong className='d_price'>{currentItem.delivery_fee>0 ? currentItem.delivery_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '무료배송'}</strong></dd>
            </dl>
            <dl className='d-flex justify-content-between align-items-center w-100 mb-0'>
              <dt>합계</dt>  
              <dd><strong className='fc_red t_price'>{currentItem?.total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
            </dl>
          </div>
        </div>

      </div>
    </div>
    <div className="card mb-2">
      <div className="card-body">
        <div>
          <label>결제방법</label>
          <p>{currentItem.pay_method === 'card' ? '현장결제(카드)' : '현장결제(현금)'}</p>
        </div>
        <div>
          <label>이름</label>
          <p>{currentItem.name}</p>
        </div>  
        <div>
          <label>휴대폰번호</label>
          <p>{currentItem.mobile}</p>
        </div>  
        <div>
          <label>배송지</label>
          <p>
            {currentItem.address1}
          </p>
        </div>
        <div>
          <label>상세주소</label>
          <p>
            {currentItem.address2}
          </p>
        </div>
        <div>
          <label>요청사항(선택)</label>
          <p>
            {currentItem.memo || '없음'}
          </p>
        </div>
      </div>
    </div>
    </div>}
    </>
  );
}
