
import { SET_CURRENT_LEAFLET } from './types'
import leafletService from '@/services/leaflet.service';

export const createLeaflet = (params) => (dispatch) => {
  return leafletService.createLeaflet(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateLeaflet = (leaflet_id, params) => (dispatch) => {
  return leafletService.updateLeaflet(leaflet_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteLeaflet = (leaflet_id) => (dispatch) => {
  return leafletService.deleteLeaflet(leaflet_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const leaflets = (params) => (dispatch) => {
  return leafletService.leaflets(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const leaflet = (leaflet_id) => (dispatch) => {
  return leafletService.leaflet(leaflet_id)
    .then((response)=>{
      
      dispatch({
        type: SET_CURRENT_LEAFLET,
        payload: response.data,
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};