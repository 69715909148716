import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import account from '@/reducers/account';
import common from '@/reducers/common';
import user from '@/reducers/user';
import leaflet from '@/reducers/leaflet';
import cart from '@/reducers/cart';
import ui from '@/reducers/ui';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart']
};

export const rootReducer = combineReducers({
  common,
  account,
  user,
  leaflet,
  cart,
  ui
});

export default persistReducer(persistConfig, rootReducer);
