import api from '@/services/api';
import queryString from 'query-string';

class OrderService {
  createOrder(params) {
    return api.post('/orders', params)
  }

  order(order_id) {
    return api.get(`/orders/${order_id}`)
  }

  orders(params) {
    return api.get(`/orders?${queryString.stringify(params)}`)
  }

  ordersInProgress() {
    return api.get(`/orders/status/in-progress`)
  }

  updateOrder(order_id, params) {
    return api.put(`/orders/${order_id}`, params)
  }

  deleteOrder(order_id) {
    return api.delete(`/orders/${order_id}`)
  }

  cancelOrderCartItem(order_id, params) {
    return api.put(`/orders/${order_id}/cart-item/cancel`, params)
  }

  changeStatus(params){
    return api.put(`/orders/status/${params.status}`, params)
  }

  downloadExcel(params){
    return api.get(`/orders/download/excel?${queryString.stringify(params)}`)
  }
}

export default new OrderService();
