import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from '@/components/ContentHeader';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useDynamicRefs from 'use-dynamic-refs';
import config from '@/config';
import _ from 'lodash';
import history from '@/helpers/history';
import { toast } from 'react-toastify';
import DaumPostcode from 'react-daum-postcode';

import { me, updateAccount, updateAccountShop } from '@/actions/account';
import { updateAccountApiKey, getAccountInfo } from '@/actions/jbmj';
import { uploadImage } from '@/actions/product';

function AccountSetting() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const [currentItem, setCurrentItem] = useState(null);
  const [openPostcode, setOpenPostcode] = useState(false);
  const [jbmjAccount, setJBMJAccount] = useState(null);

  const validationSchema = Yup.object().shape({
    shop: Yup.object().shape({
      name: Yup.string(),
      introdution: Yup.string(),
      memo: Yup.string(),
      opening_hour: Yup.string(),
      holiday: Yup.string(),
      tel: Yup.string(),
      tel2: Yup.string(),
      address: Yup.string(),
      image: Yup.string(),
      delivery_area: Yup.string(),
      min_sale_amt: Yup.number().min(0),
      delivery_fee: Yup.number().min(0),
      delivery_fee_free_amt: Yup.number().min(0)
    })
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const validationSchema2 = Yup.object().shape({
    jbmj_user_id: Yup.string(),
    jbmj_password: Yup.string()
  });

  const formOptions2 = {
    resolver: yupResolver(validationSchema2)
  };

  const validationSchema3 = Yup.object().shape({
    password: Yup.string(),
    confirm_password: Yup.string(),
    name: Yup.string(),
    tel: Yup.string(),
    mobile: Yup.string(),
    email: Yup.string()
  });

  const formOptions3 = {
    defaultValues: {
      delivery_fee: 0,
      delivery_fee_free_amt: 0
    },
    resolver: yupResolver(validationSchema3)
  };

  const { register, handleSubmit, setValue, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;

  const shop = watch('shop');

  const { register: register2, handleSubmit: handleSubmit2, setValue: setValue2, reset: reset2, formState: formState2, watch: watch2 } = useForm(formOptions2);
  const { errors: errors2 } = formState2;


  const { register: register3, handleSubmit: handleSubmit3, setValue: setValue3, reset: reset3, formState: formState3, watch: watch3 } = useForm(formOptions3);
  const { errors: errors3 } = formState2;

  useEffect(() => {
    dispatch(me())
    .then((res) => {
      reset(res);
      console.log('me>res', res)
      setCurrentItem(res);

      reset3({
        password: '',
        password_confirm: '',
        name: res.name,
        mobile: res.mobile,
        email: res.email
      })

      dispatch(getAccountInfo(res.id))
      .then((res) => {
        console.log('getAccountInfo', res)
        setJBMJAccount(res)
      })
    })
    .catch(message => {
      history.navigate('/', {replace: true})
    });

  }, []);

  const onShopUpdateSubmit = (data) => {
    dispatch(updateAccountShop(currentItem.id, data))
    .then((res) => {
      toast.success('매장정보를 수정했습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
    .catch(message => {
      console.log('message')
    })
  }
  
  const onAccountUpdateSubmit = (data) => {
    console.log('onAccountUpdateSubmit', data)

    dispatch(updateAccount(currentItem.id, data))
    .then((res) => {
      toast.success('계정정보를 수정했습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
    .catch(message => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
  }

  const onConnectJBMJSubmit = (data) => {
    dispatch(updateAccountApiKey(currentItem.id, data))
    .then((res) => {
      toast.success('문자 연동작업을 완료했습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });

      setCurrentItem({
        ...currentItem,
        isConnectedJBMJ: true
      })
      
      setJBMJAccount(res)
    })
    .catch(message => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
    
  }

  const onUploadStoreImage = (e) => {
    const img = e.target.files[0];

    const formData = new FormData();
    formData.append('image', img);

    dispatch(uploadImage(formData))
    .then(res=>{
      console.log(res)
      setValue('shop.image',`${config.API_SERVER_HOST}/files/${res.filename}`)
    })
    .catch(err=>console.log(err))
    e.target.value = null;
  }

  const handleAddress = {
    // 버튼 클릭 이벤트
    clickButton: () => {
        setOpenPostcode(current => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data) => {
      setOpenPostcode(false);
      setValue('shop.address',data.address)
    },
  }
  
  return (
    <>
    <section className="content pt-3">
        <div className="container">

          <div className="new_box_s">

            <div className="card-body">
              <div className='row'>
                <div className='col-md-5'>
                  <div className='position-relative upload-img '>
                    <label className='d-flex justify-content-center align-items-center'> 
                      {shop?.image ? (<img src={`${shop?.image}`} width='100%' height='350px'/>) : (<img src={`/images/placeholder-banner.png`} width='100%' height='350px'/>)}                               
                    </label>
                    <input type='file' className='position-absolute' accept='image/png, image/gif, image/jpeg'  onChange={onUploadStoreImage}/>
                  </div>
                </div>
                <div className='col-md-1'>

                </div>
                <div className='col-md-6'>
                  <form  onSubmit={handleSubmit(onShopUpdateSubmit)}>
                  <div className="form-group">
                    <label htmlFor="name">상호명</label>
                    <input type="text" className="form-control" id="name" name="shop.name" {...register(`shop.name`)} placeholder="직지마트"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="introdution">소개글</label>
                    <textarea className="form-control" id="introdution" name="shop.introdution" {...register(`shop.introdution`)} rows="3"></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="tel">전화번호1</label>
                    <input type="text" className="form-control" id="tel" name="shop.tel" {...register(`shop.tel`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} placeholder="062-000-0000"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="tel">전화번호2</label>
                    <input type="text" className="form-control" id="tel2" name="shop.tel2" {...register(`shop.tel2`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} placeholder="062-000-0000"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="opening_hour">운영시간</label>
                    <input type="text" className="form-control" id="opening_hour" name="shop.opening_hour" {...register(`shop.opening_hour`)} placeholder="09:00 ~ 21:00"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="delivery_hour">배달시간</label>
                    <input type="text" className="form-control" id="delivery_hour" name="shop.delivery_hour" {...register(`shop.delivery_hour`)} placeholder="09:00 ~ 21:00"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="tel">최소 주문금액</label>
                    <input type="number" className="form-control" id="min_sale_amt" name="shop.min_sale_amt" {...register(`shop.min_sale_amt`)} placeholder="0"/>
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="tel">배송비</label>
                    <input type="number" className="form-control" id="delivery_fee" name="shop.delivery_fee" {...register(`shop.delivery_fee`)} placeholder="5000"/>
                  </div> */}
                  {/* <div className="form-group"> 
                    <label htmlFor="tel">무료배송 기준금액</label>
                    <input type="number" className="form-control" id="delivery_fee_free_amt" name="shop.delivery_fee_free_amt" {...register(`shop.delivery_fee_free_amt`)} placeholder="20000"/>
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="holiday">배달가능지역</label>
                    <input type="text" className="form-control" id="delivery_area" name="shop.delivery_area" {...register(`shop.delivery_area`)} placeholder="참단,용두"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="holiday">휴무일</label>
                    <input type="text" className="form-control" id="holiday" name="shop.holiday" {...register(`shop.holiday`)} placeholder="연중무휴"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">주소</label>
                    <div className="input-group">
                      {!openPostcode && <input type="text" className="form-control" id="address" name="shop.address" {...register(`shop.address`)} readOnly={!openPostcode}/>}
                      {openPostcode && 
                        <DaumPostcode 
                            onComplete={handleAddress.selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                            autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                            defaultQuery={shop?.address} // 팝업을 열때 기본적으로 입력되는 검색어 
                      />}
                      {!openPostcode && <div className="input-group-append">
                      <button type="button" onClick={handleAddress.clickButton} className="btn btn-primary">주소입력</button>
                      </div>}
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">수정</button>
                </form>

                </div>
              </div>
            </div>
          </div>

          {currentItem && <div className="new_box_s">
            <div className="card-header">
              <h3 className="card-title">문자</h3>
            </div>
            <div className="card-body">
              {!currentItem?.isConnectedJBMJ ? <>
                <form  onSubmit={handleSubmit2(onConnectJBMJSubmit)}>
                  <div className="form-group">
                    <label htmlFor="name">아이디</label>
                    <input type="text" className="form-control" id="jbmj_user_id" name="jbmj_user_id" {...register2(`jbmj_user_id`)} placeholder="직방문자 아이디"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">패스워드</label>
                    <input type="password" className="form-control" id="jbmj_password" name="jbmj_password" {...register2(`jbmj_password`)} placeholder="직방문자 패스워드"/>
                  </div>
                  <button type="submit" className="btn btn-primary">로그인</button>
                </form>
                <div className='mt-3'>
                  문자발송을 위해서 직방문자 연동이 필요합니다.<br/>
                  <a href='https://jbmjw.co.kr'>https://jbmjw.co.kr</a>&nbsp;가입후 이용해 주세요.
                </div>
              </> : <>
                {jbmjAccount && <table className="table table-bordered text-center table-hover">
                    <tbody>
                      <tr>
                        <th>남은포인트</th><td>{jbmjAccount.my_point}</td>
                      </tr>
                      <tr>
                        <th style={{verticalAlign:'middle'}}>발송차감포인트</th><td>
                          <table className="table table-bordered text-center table-hover">
                            <tr>
                              <th>SMS</th><td>{jbmjAccount.require_send_point.sms}</td>
                            </tr>
                            <tr>
                              <th>LMS</th><td>{jbmjAccount.require_send_point.lms}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                </table>}
              </>}
            </div>
          </div>}

          <div className="new_box">
            <div className="card-header">
              <h3 className="card-title">계정정보</h3>
            </div>
            <div className="card-body">
              <form  onSubmit={handleSubmit3(onAccountUpdateSubmit)}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="password">변경 비밀번호</label>
                      <input type="password" className="form-control" id="password" name="password" {...register3(`password`)} placeholder="변경 비밀번호"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="confirm_password">변경 비밀번호 확인</label>
                      <input type="password" className="form-control" id="confirm_password" name="confirm_password" {...register3(`confirm_password`)} placeholder="변경 비밀번호 확인"/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">이름</label>
                    <input type="text" className="form-control" id="name" name="name" {...register3(`name`)} placeholder="홍길동"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">연락처</label>
                    <input type="text" className="form-control" id="mobile" name="mobile" {...register3(`mobile`,{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} placeholder="010-000-0000"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">이메일</label>
                    <input type="text" className="form-control" id="email" name="email" {...register3(`email`)} placeholder="hello@martshop.co.kr"/>
                  </div>
                  <button type="submit" className="btn btn-primary">수정</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AccountSetting;
