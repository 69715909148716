import React, {useState} from 'react'
// import { CKEditor } from 'ckeditor4-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import api from '@/services/api';
import config from '@/config';

export default function FormCKEditor({ data, handleChange, setImage }) {
  const [flag, setFlag] = useState(false);

  const contentChangeHandler = editor => {
    handleChange(editor.getData());
  }

  const customUploadAdapter = (loader) => { // (2)
    return {
      upload(){
        return new Promise ((resolve, reject) => {
          const data = new FormData();
          loader.file.then( (file) => {
            data.append("name", file.name);
            data.append("file", file);

            api.post('/boards/editor/image', data)
              .then((res) => {
                if(!flag){
                  setFlag(true);
                }
                resolve({
                    default: `${config.API_SERVER_HOST}/boards/editor/image/${res.data.filename}`
                });
              })
              .catch((err)=>reject(err));
          })
        })
      }
    }
  }

  function uploadPlugin (editor){
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    }
  }

	return (
    <CKEditor
      editor={Editor}
      config={{
        extraPlugins: [uploadPlugin],
      }}
      data={data}
      onChange={(event, editor)=>contentChangeHandler(editor)}
      onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }/>
	);
}
