export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden"
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
}

export function getIsDocumentHidden() {
  return document[getBrowserDocumentHiddenProp()]
}


export const sleep = (time) =>
  new Promise((res) => setTimeout(res, time));

export const calculateWindowSize = (windowWidth) => {
  if (windowWidth >= 1200) {
    return 'lg';
  }
  if (windowWidth >= 992) {
    return 'md';
  }
  if (windowWidth >= 768) {
    return 'sm';
  }
  return 'xs';
};

export const setWindowClass = (classList) => {
  const window =
    document && document.getElementById('root');
  if (window) {
    window.classList = classList;
  }
};
export const addWindowClass = (classList) => {
  const window =
    document && document.getElementById('root');
  if (window) {
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList) => {
  const window =
    document && document.getElementById('root');
  if (window) {
    window.classList.remove(classList);
  }
};
