import { Navigate } from "react-router";
import { useSelector } from "react-redux";

function AdminRoute(props) {
  const currentUser = useSelector(state => state.user);

  if(!currentUser?.isLoggedIn || currentUser.user.role !== 'Admin'){
    return props.redirectPath ? <Navigate to={props.redirectPath} /> : <Navigate to={`/`} />;
  }
  
  return props.children;
}

export default AdminRoute;
