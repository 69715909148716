import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Gnb() {
  const params = useParams();
  const { items } = useSelector((state) => state.cart);
  const cartQty = items
    .map((item) => item.qty)
    .reduce((prev, current) => prev + current, 0);
  
  const { leaflet } = useSelector((state) => state.leaflet);

  return (
    <div className="gnb_bottom text-center">
      <ul>
        <li style={{width: `${leaflet && leaflet.shop?'calc(100% / 4)':'calc(100% / 3)'}`}}>
          <NavLink to={`/shop/${params.leaflet_id}`} exact>
            <div className="iconN"><i className="fas fa-home"></i></div>
            <p className="home_btn_text">홈</p>
          </NavLink>
        </li>
        {leaflet &&  leaflet.shop && leaflet.shop.tel && <li>
          <a href={`tel:${leaflet.shop.tel}`}>
            <div className="iconN"><i className="fas fa-phone-alt"></i></div>
            <p>전화</p>
          </a>
        </li>}
        <li style={{width: `${leaflet && leaflet.shop?'calc(100% / 4)':'calc(100% / 3)'}`}}>
          <NavLink to={`/shop/cart/${params.leaflet_id}`}>
            <div className="iconN iconClass">
              <i className="fas fa-shopping-cart"></i>
              {cartQty > 0 && <span className="badge badge-light">{cartQty}</span>}
            </div>
            <p>장바구니</p>
          </NavLink>
        </li>
        {leaflet && leaflet.shop && <li>
          <NavLink to={`/shop/info/${params.leaflet_id}`}>
            <div className="iconN"><i className="fas fa-store"></i></div>
            <p>매장정보</p>
          </NavLink>
        </li>}
        {/* <li style={{width: `${leaflet && leaflet.shop?'calc(100% / 4)':'calc(100% / 3)'}`}}>
          <button type="button" className='btn'>
            <div className="iconN"><i className="fas fa-share-alt"></i></div>
            <p>공유</p>
          </button>
        </li> */}
      </ul>
    </div>
  );
}
