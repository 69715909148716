import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Checkbox from '@/components/Checkbox';
import * as Yup from 'yup';
import history from '@/helpers/history';

import { signup, existsUserId } from "@/actions/account";

export default function Register() {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required('필수값입니다.'),
    password: Yup.string()
              .required('필수값입니다.')
              .min(8, '패스워드는 최소 8자리 이상 입력해 주세요.')
              .max(20, '패스워드는 최대 20자리 까지 입력해 주세요.'),
    confirm_password: Yup.string()
              .required('필수값입니다.')
              .oneOf([Yup.ref('password')], '패스워드가 일치하지 않습니다.'),
    accept_terms: Yup.bool().oneOf([true], '가입약관에 동의해 주셔야 합니다.'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  const onSubmit = (params) => {
    dispatch(existsUserId(params.user_id))
    .then((res) => {
      if(res.isExistsUserId){
        
      }else{
        dispatch(signup({
          user_id: params.user_id,
          password: params.password,
          confirm_password: params.confirm_password,
        }))
        .then(() => {
          history.navigate('/', { replace: true })
        })
        .catch(message => {
          toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        });
      }
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
      <div className="login-page">
        <div className="login-box">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                  <h1 className="h1"><b>Mart</b>Shop</h1>
                </div>
                <div className="card-body">
                  <div className={`input-group mb-3 ${errors.user_id?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id="user_id"
                        name="user_id"
                        className="form-control" 
                        {...register('user_id',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^A-Za-z0-9._-]/g,'').toLowerCase();
                        }})}
                        placeholder='아이디'
                        maxLength="20"
                      />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user"></span>
                      </div>
                    </div>
                    <div className='invalid-feedback'>{errors.user_id?.message}</div>
                  </div>
                  <div  className={`input-group mb-3 ${errors.password?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='password'
                      id="password"
                      name="password"
                      onPaste={e=>e.preventDefault()}
                      className="form-control"
                      {...register('password',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g,'');
                        }})}
                      placeholder='패스워드'
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <div className='invalid-feedback'>{errors.password?.message}</div>
                  </div>
                  <div className={`input-group mb-3 ${errors.confirm_password?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='password'
                      id="confirm_password"
                      name="confirm_password"
                      className="form-control"
                      {...register('confirm_password',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g,'');
                        }})}
                      placeholder="패스워드 재확인"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <div className='invalid-feedback'>{errors.confirm_password?.message}</div>
                  </div>
                  <div className="row">
                    <div  className={`col-12 ${errors.accept_terms?.message ? 'is-invalid' : ''}`}>
                      <div className="icheck-primary">
                        <Checkbox id="accept_terms" name="accept_terms" register={register} checked={formData.accept_terms}>
                          가입시 <a href="#">약관</a>에 동의합니다.
                        </Checkbox>
                      </div>
                      <div className='invalid-feedback'>{errors.accept_terms?.message}</div>
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                <Link to="/" className="btn btn-default" >로그인</Link>
                <button type="submit" className="btn btn-primary">회원가입</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
