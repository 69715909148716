import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import history from '@/helpers/history';

import Router from '@/routes'

import {useWindowSize} from '@/hooks/useWindowSize';
import { refreshToken } from '@/actions/account';
import { setIsLoading, setIsShowModalBackdrop } from './actions/common';
import {calculateWindowSize} from '@/helper';
import {setScreenSize} from '@/actions/ui';

function App() {
  const dispatch = useDispatch();

  history.navigate = useNavigate();
  history.location = useLocation();

  const { isShowModalBackdrop } = useSelector((state) => state.ui);

  const windowSize = useWindowSize();
  const screenSize = useSelector((state) => state.ui.screenSize);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setScreenSize(size));
    }
  }, [windowSize]);

  useEffect(() => {
    dispatch(refreshToken())
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(setIsShowModalBackdrop(false));
    dispatch(setIsLoading(false));
    return () => {
      // dispatch(setIsLoading(false));
      // dispatch(setIsShowModalBackdrop(false));
    };
  },[]);

  return (
    <>
      <Router/>
      {
        isShowModalBackdrop && <div className="modal-backdrop fade show"></div>
      }
    </>
  );
}

export default App;
