import api from '@/services/api';
import queryString from 'query-string';

class LeafletService {
  createLeaflet(params) {
    return api.post('/leaflets', params)
  }

  leaflet(leaflet_id) {
    return api.get(`/leaflets/${leaflet_id}`)
  }

  leaflets(params) {
    return api.get(`/leaflets?${queryString.stringify(params)}`)
  }

  updateLeaflet(leaflet_id, params) {
    return api.put(`/leaflets/${leaflet_id}`, params)
  }

  deleteLeaflet(leaflet_id) {
    return api.delete(`/leaflets/${leaflet_id}`)
  }
}

export default new LeafletService();
