export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_SHOW_MODAL_BACKDROP = 'SET_IS_SHOW_MODAL_BACKDROP';
export const SET_IS_MENU_SIDEBAR_COLLAPSED = 'SET_IS_MENU_SIDEBAR_COLLAPSED';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';
export const SET_BASE_PATH = 'SET_BASE_PATH';
export const CLEAR_BASE_PATH = 'CLEAR_BASE_PATH';
export const SET_CURRENT_LEAFLET = 'SET_CURRENT_LEAFLET';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_ONE_FROM_CART = 'REMOVE_ONE_FROM_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';