import { Navigate } from "react-router";
import { useSelector } from "react-redux";

function PrivateRoute(props) {
  const currentUser = useSelector(state => state.user);

  const { basePath } = useSelector((state) => state.common);

  if(!currentUser?.isLoggedIn){
    return props.redirectPath ? <Navigate to={props.redirectPath} /> : <Navigate to={`/`} />;
  }
  
  return props.children;
}

export default PrivateRoute;
