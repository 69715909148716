
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {Map, MapMarker, MapInfoWindow, CustomOverlayMap, Circle} from 'react-kakao-maps-sdk';
import { me } from '@/actions/account';
import { ordersInProgress } from '@/actions/order';
import history from '@/helpers/history';

function OrderMap() {
  const dispatch = useDispatch();
  
  const [mapOption, setMapOption] = useState({
    center: {lat: 37.5519267, lng: 126.9918251},
    level: 3
  }); 

  const [circleRadius, setCircleRadius] = useState(500); 
  const [accountInfo, setAccountInfo] = useState(null);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    dispatch(me())
    .then((account) => {
      dispatch(ordersInProgress())
      .then((res) => {
        console.log('orders', res)
        setOrders(res)
        setAccountInfo(account);
        if(account?.shop?.address_map){
          setMapOption({
            center: {
              lat: account?.shop?.address_map?.y,
              lng: account?.shop?.address_map?.x
            },
            level: 3
          })
        }
      })
      .catch(message => {
        history.navigate('/', {replace: true})
      });
    })
    .catch(message => {
      history.navigate('/', {replace: true})
    });
  }, []);

  const setCircle = (radius, zoomLevel) => {
    setCircleRadius(radius)
    if(zoomLevel){
      setMapOption({
        ...mapOption,
        level: zoomLevel
      })
    }
  }

  return (
    <>
      <section className="content p-0">
      {accountInfo && <><Map
          center={mapOption.center}
          style={{
            width: "100%",
            height: "calc(100vh - 50px)",
          }}
          level={mapOption.level}
        >
            {
              orders && orders.map(order=> order?.address_map &&<MapMarker
                key={`order-${order.id}`}
                position={{
                  lat: order?.address_map?.y,
                  lng: order?.address_map?.x
                }}
                image={{
                  src: "/images/marker/home.png", // 마커이미지의 주소입니다
                  size: {
                    width: 45,
                    height: 45,
                  },
                  options: {
                    offset: {
                      x: 22.5,
                      y: 45,
                    },
                  },
                }}
              >
                <div style={{ padding: "5px", color: "#000", zIndex:2, textAlign:'center'}}>
                  {order.name}
                  {/* &nbsp;|&nbsp;
                  {order.status === 'new' && '신규주문'}
                  {order.status === 'prepare' && '상품준비중'}
                  {order.status === 'delivery' && '배송중'}
                  {order.status === 'delivery-complete' && '배송완료'}
                  {order.status === 'cancel' && '취소'} */}
                  &nbsp;|&nbsp;
                  {order.mobile}
                </div>
              </MapMarker>)
            }

              <MapMarker
              position={mapOption.center}
              image={{
                src: "/images/marker/cart.png", // 마커이미지의 주소입니다
                size: {
                  width: 60,
                  height: 60,
                },
                options: {
                  offset: {
                    x: 30,
                    y: 60,
                  },
                },
              }}
            />
            <Circle
              center={mapOption.center}
              radius={circleRadius}
              strokeWeight={2} // 선의 두께입니다
              strokeColor={"#00a0e9"} // 선의 색깔입니다
              strokeOpacity={0.4} // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
              strokeStyle={"solid"} // 선의 스타일입니다
              fillColor={"#00a0e9"} // 채우기 색깔입니다
              fillOpacity={0.4} // 채우기 불투명도입니다
            />
        </Map>
        <div className='btn-group position-absolute' style={{top: '60px', right:'10px', zIndex:1}}>
          <button type="button" className={`btn ${circleRadius === 500 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(500, 3)}>반경 500M</button>
          <button type="button" className={`btn ${circleRadius === 1000 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(1000, 4)}>반경 1KM</button>
          <button type="button" className={`btn ${circleRadius === 2000 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(2000, 5)}>반경 2KM</button>
          <button type="button" className={`btn ${circleRadius === 3000 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(3000, 6)}>반경 3KM</button>
          <button type="button" className={`btn ${circleRadius === 4000 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(4000, 7)}>반경 4KM</button>
          <button type="button" className={`btn ${circleRadius === 5000 ? 'btn-primary':'btn-default'}`} onClick={()=>setCircle(5000, 8)}>반경 5KM</button>
        </div></>}
      </section>
    </>
  );
}

export default OrderMap;
