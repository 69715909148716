import axios from 'axios';
import config from '@/config';

const instance = axios.create({
  baseURL: config.API_SERVER_HOST,
  headers: {
    'content-Type': 'application/json'
  },
  withCredentials: true
});

export default instance;
