import {
  UPDATE_USER_INFO
} from './types'

import jbmjService from '@/services/jbmj.service';

export const createMessage = (user_id, params) => (dispatch) => {
  return jbmjService.createMessage(user_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const cancelMessage = (user_id, message_id) => (dispatch) => {
  return jbmjService.cancelMessage(user_id, message_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const updateAccountApiKey = (user_id, params) => (dispatch) => {
  return jbmjService.updateAccountApiKey(user_id, params)
    .then((response)=>{
      if(response.data.isConnectedJBMJ){
        dispatch({
          type: UPDATE_USER_INFO,
          payload: {       
            isConnectedJBMJ: response.data.isConnectedJBMJ,
          },
        });
      }

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const getGroups = (user_id) => (dispatch) => {
  return jbmjService.getGroups(user_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const getMessages = (user_id, params) => (dispatch) => {
  return jbmjService.getMessages(user_id, params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const getAccountInfo = (user_id) => (dispatch) => {
  return jbmjService.getAccountInfo(user_id)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};