import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dashboard } from '@/actions/dashboard';
import Moment from 'react-moment';

function Dashboard() {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.user);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(dashboard())
    .then(res => {
      setCurrentItem(res);
    })
  }, []);

  return (
    <>
      {currentItem && <section className="content pt-3">
        <div className="container-fluid">

          <div  className="dasoboard_container">
            <div className="dasoboard_box">
              <div className="inner_title color_1 font_6">오늘의 주문</div>
              <div className="inner_count">
                <div className="inner_nember font_2">{currentItem.todayOrderCount}<span className="inner_tx">건</span></div>
              </div>
            </div>
            <div className="dasoboard_box">
              <div className="inner_title color_2 font_6">배송중</div>
              <div className="inner_count">
                <div className="inner_nember font_2">{currentItem.todayDeliveryOrderCount}<span className="inner_tx">건</span></div>
              </div>
            </div>
            <div className="dasoboard_box">
              <div className="inner_title color_3 font_6">상품준비중</div>
              <div className="inner_count">
                <div className="inner_nember font_2">{currentItem.todayPrepareOrderCount}<span className="inner_tx">건</span></div>
              </div>
            </div>
            <div className="dasoboard_box">
              <div className="inner_title color_4 font_6">모바일 전단</div>
              <div className="inner_count">
                <div className="inner_nember font_2">{currentItem.leafletCount}<span className="inner_tx">건</span></div>
              </div>
            </div>
          </div>

          <div  className="dasoboard_container">
            <div className="dasoboard_box">
              <div className="inner_title color_g font_6">공지사항</div>
              <div className="recent_PostsList">
                {currentItem.notices.docs.map(r=><ul>
                  <li>
                    <Link to={`/manager/support/noti/view/${r.id}`}>
                      {r.title}
                    </Link>
                  </li>
                  <li><Moment format='YYYY-MM-DD'>{r.created}</Moment></li>
                </ul>)}
              </div>
            </div>
            <div className="dasoboard_box">
              <div className="inner_title color_g font_6">문의하기</div>
              <div className="recent_PostsList">
                {currentItem.questions.docs.map(r=><ul>
                  <li>
                    <Link to={`/manager/support/qa/view/${r._id}`}>
                      {r.title}
                    </Link>
                  </li>
                  <li><span className={r.comment?'rp_yes':'rp_no'}>{r.comment?'답변 완료' : '답변 대기'}</span></li>
                </ul>)}
              </div>
            </div>
            {currentUser?.user?.isConnectedJBMJ && <div className="dasoboard_box dasoboard_box_w2">
              <div className="inner_title color_g font_6">
                예약 발송 대기
                <span>{currentItem.reservation_messages.total_item_count}건</span>
              </div>
              <div className="recent_PostsList_message">
                {currentItem.reservation_messages.item_list.map(r=><ul>
                  <li>{r.subject}</li>
                  <li className="">
                    <span className="inner_c1">M</span>
                    <span className="inner_t1">{r.require_total_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    <span className="inner_c2">D</span>
                    <span className="inner_t2"><Moment format='YYYY. MM. DD'>{r.create_at}</Moment></span>
                    <span className="inner_c3">T</span>
                    <span className="inner_t3"><Moment format='hh:mm'>{r.create_at}</Moment></span>
                  </li>
                </ul>)}
              </div>
            </div>}
          </div>

        </div>
      </section>}
    </>
  );
}

export default Dashboard;
