
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import moment from 'moment';
import useDynamicRefs from 'use-dynamic-refs';
import ContentHeader from '@/components/ContentHeader';
import { orders, updateOrder, deleteOrder, cancelOrderCartItem, changeStatus, downloadExcel } from '@/actions/order';
import _ from 'lodash';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Checkbox from '@/components/Checkbox';
import { useStateCallback } from "use-state-callback";

function LeafletList() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const { register, handleSubmit, setValue, setError, formState, watch } = useForm();
  const { errors, isSubmitting } = formState;

  const [searchFilters, setSearchFilters] = useStateCallback({
    date: {
      mode: 'today',
      from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD')
    },
    pay_method: '', 
    status: '',
    textBy: '',
    text: ''
  })

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [change_order_status, set_change_order_status] = useState('');

  const formData = watch();

  useEffect(() => {
    dispatch(orders({
      page: currentPage,
      limit: pageLimit,
      from_date: searchFilters.date.from,
      to_date: searchFilters.date.to,
      pay_method: searchFilters.pay_method,
      status: searchFilters.status,
      textBy: searchFilters.textBy,
      text: searchFilters.text,
    }))
    .then(res => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }, []);

  const searchOrder = () => {
    dispatch(orders({
      page: currentPage,
      limit: pageLimit,
      from_date: searchFilters.date.from,
      to_date: searchFilters.date.to,
      pay_method: searchFilters.pay_method,
      status: searchFilters.status,
      textBy: searchFilters.textBy,
      text: searchFilters.text,
    }))
    .then(res => {
      setIdList(res.docs.map(item=>item.id))
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })
  }

  const handlePageClick = (event) => {
    dispatch(orders({
      page: event.selected + 1,
      limit: pageLimit,
      from_date: searchFilters.date.from,
      to_date: searchFilters.date.to,
      pay_method: searchFilters.pay_method,
      status: searchFilters.status,
      textBy: searchFilters.textBy,
      text: searchFilters.text,
    }))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })

    setCurrentPage(event.selected + 1);
  };

  const changePageLimit = event => {
    dispatch(orders({
      page: currentPage,
      limit: event.target.value,
    }))
    .then(res => {
      setTotalPageCount(res.totalPages);
      setTotalItemCount(res.totalDocs);
      setCurrentItems(res.docs);
    })

    setPageLimit(event.target.value);
  }

  const cancelCartItem = (order_id) => {
    swal({
      text: "선택한 상품을 부분취소하시겠습니까?",
      icon: "warning",
      dangerMode: true,
      buttons: ["아니요", "네"],
    })
    .then((willDelete) => {
      if (willDelete) {
        let filteredItem = _.filter(Object.keys(formData?.cart_item_cancel_id?.[order_id]||[]).map(key=>{return {id: key, checked: (formData?.cart_item_cancel_id?.[order_id]||[])[key]}}), row=>row.checked);
        let cancel_cart_item_ids = filteredItem.map(item=>item.id)
        let cancel_reason = formData?.cart_item_cancel_reason?.[order_id];
        
        dispatch(cancelOrderCartItem(order_id, {
          ids: cancel_cart_item_ids,
          cancel_reason: cancel_reason
        })).then(res=>{
          var index = _.findIndex(currentItems, {id: order_id});

          currentItems[index].total_price = res.total_price;
          currentItems[index].status = res.status;
          currentItems[index].cart = res.cart;

          setValue('cart_item_cancel_id',[])
          setValue('cart_item_cancel_reason',[])

          swal("선택상품을 부분취소하였습니다.", {
            icon: "success",
          });
        }).catch(e=>{
          console.log(e);
        })
      }
    }); 
  }

  const changeDateFilter = (mode) => {
    let from_date = '';
    let to_date = '';

    switch (mode) {
      case '1week':
        from_date = moment().subtract(1, 'week').format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
      case '1month':
        from_date = moment().subtract(1, 'months').format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
      case '3month':
        from_date = moment().subtract(3, 'months').format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
      case '6month':
        from_date = moment().subtract(6, 'months').format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
      case '1year':
        from_date = moment().subtract(1, 'years').format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
      default:
        mode = 'today';
        from_date = moment().format('YYYY-MM-DD')
        to_date = moment().format('YYYY-MM-DD')
        break;
    }

    setSearchFilters({
      ...searchFilters,
      date: {
        mode: mode,
        from: from_date,
        to: to_date
      },
    })

  }

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangeStatus = () => {
    if(!change_order_status) {
      toast.error('변경할 상태를 선택해주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    } else if(checkList.length === 0) {
      toast.error('변경할 주문을 선택해주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
      return;
    }

    dispatch(changeStatus({
      status: change_order_status,
      ids: checkList
    }))
    .then((res) => {
      checkList.forEach(id=>{
        const index = _.findIndex(currentItems, {id});
        currentItems.splice(index, 1, {...currentItems[index], status: change_order_status});
      })
      setCheckList([]);
      set_change_order_status('')
      toast.success('주문상태 변경을 완료했습니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  const onChangeOrderMemo = (order_id) => {
    dispatch(updateOrder(order_id,{
      admin_memo: formData.order_item_admin_memo[order_id]
    }))
    .then((res) => {
      toast.success('관리자 메모를 수정했습니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    })
  }

  return (
    <>
      <section className="content pt-3 pb-3">
        {/* <div className="container-fluid"> */}
        <div className="container">
          <div className='new_box_s'>
            <div className="box_title font_7">
              주문내역
            </div>
            <div className='card-body'>
              <form>
                <div class="form-row align-items-center">
                  <div className='col-auto'>
                    <div className='btn-group mb-2 new_list_dd'>
                      <button type="button" className={`btn ${searchFilters.date.mode === 'today' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('today')}>오늘</button>
                      <button type="button" className={`btn ${searchFilters.date.mode === '1week' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('1week')}>일주일</button>
                      <button type="button" className={`btn ${searchFilters.date.mode === '1month' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('1month')}>한달</button>
                      <button type="button" className={`btn ${searchFilters.date.mode === '3month' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('3month')}>3개월</button>
                      <button type="button" className={`btn ${searchFilters.date.mode === '6month' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('6month')}>6개월</button>
                      <button type="button" className={`btn ${searchFilters.date.mode === '1year' ? 'btn-primary' : 'btn-default'}`} onClick={()=>changeDateFilter('1year')}>1년</button>
                    </div>  
                  </div>
                  <div class="col-auto">
                    <label class="sr-only" for="order_from_date">order_from_date</label>
                    <input type="date" class="form-control mb-2" id="order_from_date" onChange={e=>{
                      setSearchFilters({
                        ...searchFilters,
                        date: {
                          ...searchFilters.date,
                          from: e.target.value,
                          mode: ''
                        }
                      })
                    }} value={searchFilters.date.from} placeholder="검색 시작일" />
                  </div>
                  <div class="col-auto">
                    <label class="sr-only" for="order_to_date">order_from_date</label>
                    <input type="date" class="form-control mb-2" id="order_to_date"  onChange={e=>{
                      setSearchFilters({
                        ...searchFilters,
                        date: {
                          ...searchFilters.date,
                          to: e.target.value,
                          mode: ''
                        }
                      })
                    }} value={searchFilters.date.to} placeholder="검색 시작일" />
                  </div>
                </div>
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <label class="sr-only" for="order_status">order_status</label>
                    <select id="search_order_status" onChange={e=>{
                      setSearchFilters({
                        ...searchFilters,
                        status: e.target.value
                      })
                    }} class="form-control">
                      <option value='' selected={searchFilters.status === ''}>전체주문상태</option>
                      <option value='new' selected={searchFilters.status === 'new'}>신규주문</option>
                      <option value='prepare' selected={searchFilters.status === 'prepare'}>상품준비중</option>
                      <option value='delivery' selected={searchFilters.status === 'delivery'}>배송중</option>
                      <option value='delivery-complete' selected={searchFilters.status === 'delivery-complete'}>배송완료</option>
                      <option value='cancel' selected={searchFilters.status === 'cancel'}>주문취소</option>
                    </select>
                  </div>
                  <div class="col-auto">
                    <label class="sr-only" for="order_pay_method">Username</label>
                    <select id="order_pay_method" onChange={e=>{
                      setSearchFilters({
                        ...searchFilters,
                        pay_method: e.target.value
                      })
                    }} class="form-control">
                      <option value='' selected>전체결제방법</option>
                      <option value='card' selected={searchFilters.pay_method === 'card'}>현장결제(카드)</option>
                      <option value='cash' selected={searchFilters.pay_method === 'cash'}>현장결제(현금)</option>
                    </select>
                  </div>
                  <div class="col-auto">
                    <label class="sr-only" for="inlineFormInputGroup">Username</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <select id="inputState" onChange={e=>{
                            setSearchFilters({
                              ...searchFilters,
                              textBy: e.target.value
                            })
                          }} class="form-control">
                          <option value='name' selected={searchFilters.textBy === 'name'}>주문자명</option>
                          <option value='mobile' selected={searchFilters.textBy === 'mobile'}>전화번호</option>
                        </select>
                      </div>
                      <input type="text" class="form-control ml-2n" onChange={e=>{
                        setSearchFilters({
                          ...searchFilters,
                          text: e.target.value
                        })
                      }} placeholder="검색어" value={searchFilters.text}/>
                    </div>
                  </div>
                  <div class="col-auto nne_w">
                    <button type="button" class="btn btn-primary" onClick={()=>{
                      searchOrder();
                    }}>검색</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="new_box_s">
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex mb-3'>
                  <select id="order_status" class="form-control" onChange={e=>set_change_order_status(e.target.value)}>
                    <option value=''>전체주문상태</option>
                    <option value='new' selected={change_order_status === 'new'}>신규주문</option>
                    <option value='prepare' selected={change_order_status === 'prepare'}>상품준비중</option>
                    <option value='delivery' selected={change_order_status === 'delivery'}>배송중</option>
                    <option value='delivery-complete' selected={change_order_status === 'delivery-complete'}>배송완료</option>
                    <option value='cancel' selected={change_order_status === 'cancel'}>주문취소</option>
                  </select>
                  <button type='button' className='btn btn-primary ml-1 font_6' onClick={onChangeStatus}>상태수정</button>
                </div>
                <div className='d-flex'>
                  <select class="form-control mr-1" style={{width: '80px'}} onChange={changePageLimit}>
                    <option value='10' selected={pageLimit == 10}>10</option>
                    <option value='20' selected={pageLimit == 20}>20</option>
                    <option value='50' selected={pageLimit == 30}>50</option>
                    <option value='100' selected={pageLimit == 40}>100</option>
                  </select>
                  <button className='btn btn-success font_6' onClick={()=>dispatch(downloadExcel())} style={{width: '150px'}}>주문내역 다운로드</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-bordered text-center table-hover">
                  <colgroup>
                      <col width='50px'/>
                      <col width='40px'/>
                      <col width="90px"/>
                      <col width="70px"/>
                      <col width="110px"/>
                      <col style={{minWidth: '350px'}}/>
                      <col width="120px"/>
                      <col width="250px"/>
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="align-middle" rowSpan={2}>순번</th>
                      <th className="align-middle" rowSpan={2}>
                        <input type='checkbox' onChange={onChangeAll} checked={checkList.length === idList.length}/>
                      </th>
                      <th className="align-middle">주문일</th>
                      <th className="align-middle">주문자</th>
                      <th className="align-middle">연락처</th>
                      <th className="align-middle" rowSpan={2}>주문상품</th>
                      <th className="align-middle">결제금액</th>
                      <th className="align-middle">요청사항</th>
                    </tr>
                    <tr>
                      <th colSpan={3}>주소</th>
                      <th className="align-middle">결제방법</th>
                      <th className="align-middle">관리메모</th>
                    </tr>
                  </thead>
                  <tbody>
                      {
                        currentItems && currentItems.map((item, index)=>(
                          <>
                            <tr key={item.id}>
                              <td rowSpan={2}>{totalItemCount - (currentPage - 1) * pageLimit - index}</td>
                              <td className="align-middle" rowSpan={2}>
                                <input type='checkbox' onChange={(e) => onChangeEach(e, item.id)} checked={checkList.includes(item.id)}/>
                              </td>
                              <td>
                                <Moment format='YYYY-MM-DD'>{item.created}</Moment>
                                <div>
                                  {item.status === 'new' && '신규주문'}
                                  {item.status === 'prepare' && '상품준비중'}
                                  {item.status === 'delivery' && '배송중'}
                                  {item.status === 'delivery-complete' && '배송완료'}
                                  {item.status === 'cancel' && '취소'}
                                </div>
                              </td>
                              <td>{item.name}</td>
                              <td>{item.mobile}</td>
                              <td rowSpan={2}>
                                {/* {JSON.stringify(item.cart)} */}
                                <div className='d-flex justify-content-between align-items-center'>
                                  {item.cart.length-1 > 0 ? <span>{`${item.cart[0].name} 외 ${item.cart.length-1}개`}</span> : <>{item.cart[0].name}</>}
                                  <button type='button' ref={setRef(`cart_item_detail_${item.id}_btn`)} className='btn btn-sm btn-primary' onClick={()=>{
                                    if(getRef(`cart_item_detail_${item.id}`).current.classList.value.indexOf('d-none')>-1){
                                      getRef(`cart_item_detail_${item.id}`).current.classList.remove('d-none')
                                      getRef(`cart_item_detail_${item.id}_btn`).current.innerText = '상세닫기';
                                    }else {
                                      getRef(`cart_item_detail_${item.id}`).current.classList.add('d-none')
                                      getRef(`cart_item_detail_${item.id}_btn`).current.innerText = '상세보기';
                                    }
                                  }}>상세보기</button>
                                </div>
                                <div ref={setRef(`cart_item_detail_${item.id}`)} className='d-none mt-2'>
                                  <table className="table table-bordered text-center table-hover">
                                    <colgroup>
                                      <col width='45px'/>
                                      <col />
                                      <col width="45px"/>
                                      <col width="80px"/>
                                    </colgroup>
                                    {item.cart.map(citem=><tr key={`${citem._id}-${citem.name}-${citem.cancel}`}>
                                      <td><Checkbox id={`cart_item_cancel_id.[${item.id}].[${citem._id}]`} name={`cart_item_cancel_id.[${item.id}].[${citem._id}]`} register={register} checked={formData?.cart_item_cancel_id?.[item.id]?.[citem._id]} disabled={citem.cancel}></Checkbox></td>
                                      <td><label htmlFor={`cart_item_cancel_id.[${item.id}].[${citem._id}]`} className='text-truncate font-weight-normal'>{!citem.cancel ? <>{citem.name}</>:<span className='text-truncate font-weight-normal' style={{textDecoration:'line-through'}}>{citem.name}</span>}</label></td>
                                      <td>{citem.qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                      <td>{(citem.qty * citem.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                    </tr>)}
                                  </table>
                                  <input type="text" name={`cart_item_cancel_reason.[${item.id}]`} 
                                    {...register(`cart_item_cancel_reason.[${item.id}]`)}
                                    className='form-control' style={{width: '100%'}} placeholder="취소 사유를 입력해 주세요."/>
                                  <button type='button' className='btn btn-sm btn-primary btn-block mt-3' onClick={()=>cancelCartItem(item.id)} disabled={!_.some(Object.keys(formData?.cart_item_cancel_id?.[item.id]||[]).map(key=>{return {id: key, checked: (formData?.cart_item_cancel_id?.[item.id]||[])[key]}}), row=>row.checked)}>선택상품 부분취소</button>
                                </div>
                              </td>
                              <td>{(item.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                              <td>
                                {item.memo}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>{item.address1} {item.address2}</td>
                              <td>
                                {item.pay_method === 'card' ? '현장결제(카드)' : '현장결제(현금)'}
                              </td>
                              <td>
                                <textarea className='form-control' style={{width: '100%'}} {...register(`order_item_admin_memo.[${item.id}]`)} placeholder="관리자 메모를 입력해 주세요.">{item.admin_memo}</textarea>
                                <button type='button' className='btn btn-sm btn-primary btn-block mt-3' onClick={()=>onChangeOrderMemo(item.id)}>수정</button>
                              </td>
                            </tr>
                          </>
                        ))
                      }

                      {
                          currentItems && currentItems.length === 0 && <tr>
                            <td colSpan={8}>주문내역이 없습니다.</td>
                          </tr>
                      }
                  </tbody>
                </table>  
              </div>
              <div className='paging text-center mt-4'>
                <ReactPaginate
                  breakLabel='...'
                  nextLabel={<span className='paging_end ml30'></span>}
                  onPageChange={handlePageClick}
                  forcePage={ currentPage - 1 }
                  pageRangeDisplayed={3}
                  pageCount={totalPageCount}
                  previousLabel={<span className='paging_first mr30'></span>}
                  renderOnZeroPageCount={null}
                  activeLinkClassName='paging_num paging_current'
                  pageLinkClassName='paging_num'
                  breakLinkClassName='paging_num'
                  previousLinkClassName='paging_num'
                  nextLinkClassName='paging_num'
                />
              </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default LeafletList;
