import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@/helpers/history';
import * as Yup from 'yup';

import { login } from "@/actions/account";

export default function Login() {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required('필수값입니다.'),
    password: Yup.string().required('필수값입니다.')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const onSubmit = (params) => {
    dispatch(login(params.user_id, params.password))
      .then(res => {
        history.navigate('/manager', { replace: true })
      })
      .catch(message => {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      });
  }

  return (
    <>
      <div className="login-page">
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                  <h1 className="h1"><b>Mart</b>Shop</h1>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`input-group mb-3 ${errors.user_id?.message ? 'is-invalid' : ''}`}>
                      <input
                        type='text'
                        id="user_id"
                        name="user_id"
                        className="form-control" 
                        {...register('user_id',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^A-Za-z0-9._-]/g,'').toLowerCase();
                        }})}
                        placeholder='아이디'
                        maxLength="20"
                      />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user"></span>
                      </div>
                    </div>
                    <div className='invalid-feedback'>{errors.user_id?.message}</div>
                  </div>
                  <div  className={`input-group mb-3 ${errors.password?.message ? 'is-invalid' : ''}`}>
                    <input
                      type='password'
                      id="password"
                      name="password"
                      onPaste={e=>e.preventDefault()}
                      className="form-control"
                      {...register('password',{
                        onChange: (e)=>{
                          e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g,'');
                        }})}
                      placeholder='패스워드'
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <div className='invalid-feedback'>{errors.password?.message}</div>
                  </div>
                    <div className="text-center mt-2 mb-3">
                    <button type="submit" className="btn btn-primary btn-block">로그인</button>
                    <Link to="/register" className="btn btn-block btn-default">회원가입</Link>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
