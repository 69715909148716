import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from '@/components/ContentHeader';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import useDynamicRefs from 'use-dynamic-refs';
import config from '@/config';
import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import Checkbox from '@/components/Checkbox';
import history from '@/helpers/history';
import randomBytes from 'randombytes';
import { toast } from 'react-toastify';

import { createLeaflet } from '@/actions/leaflet';
import { uploadImage, martSampleData } from '@/actions/product';
import { setIsLoading } from '@/actions/common';

import bannerData from '@/data/bannerData';
import cornerData from '@/data/cornerData';

function LeafletCreate() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const [selectedItemIndex, setSelectedItemIndex] = useState({sectionIndex: null, productIndex: null});

  const [showGetProductImageModal, setShowGetProductImageModal] = useState(false);
  const [showGetProductModal, setShowGetProductModal] = useState(false);
  const [showGetBannerImageModal, setShowGetBannerImageModal] = useState(false);
  const [showGetCornerImageModal, setShowGetCornerImageModal] = useState(false);

  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [searchProductName, setSearchProductName] = useState('');
  const [selectedBannerVal, setSelectedBannerVal] = useState('');
  
  const { isLoading } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    title: Yup.string().required('제목을 입력해 주세요.'),
    from_date: Yup.string(),
    to_date: Yup.string(),
    sections: Yup.array().of(
      Yup.object().shape({
        _id: Yup.string(),
        type: Yup.string().required().oneOf(['BannerImage','ImageProductItem','TextProductItem']),
        title_option: Yup.string(),
        title_text: Yup.string(),
        title_image: Yup.string(),
        product_item_column_count: Yup.number(),
        products: Yup.array().of(
          Yup.object().shape({
            _id: Yup.string(),
            barcode_no: Yup.string(),
            name: Yup.string().required('상품명을 입력해 주세요.'),
            spec: Yup.string(),
            normal_price: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable(),
            price: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).nullable().required('상품가격을 입력해 주세요.').min(1, '1원 이상입력해 주세요'),
            image: Yup.string(),
            option: Yup.string(),
            in_stock: Yup.boolean()
          })
        )
      })
    )
  });

  const initBannerSectionValue = {
    _id: randomBytes(12).toString('hex'),
    type: 'BannerImage',
    title_option: 'image',
    title_text: 'none',
    title_image: '',
    product_item_column_count: 1,
    products: []
  }
  
  const initProduct = {
    barcode_no: '',
    name: '',
    spec: '',
    normal_price: 0,
    price: 0,
    image: '',
    option: '',
    in_stock: true
  }
  
  const initImageProductSectionsValue = {
    _id: randomBytes(12).toString('hex'),
    type: 'ImageProductItem',
    title_option: 'image',
    title_text: '오늘의 특가 ⏰',
    title_image: '',
    product_item_column_count: 3,
    products: [
      {
        _id: randomBytes(12).toString('hex'),
        ...initProduct
      },
      {
        _id: randomBytes(12).toString('hex'),
        ...initProduct
      },
    ]
  }
  
  const initTextProductSectionsValue = {
    _id: randomBytes(12).toString('hex'),
    type: 'TextProductItem',
    title_option: 'image',
    title_text: '오늘의 특가 ⏰',
    title_image: '',
    product_item_column_count: 3,
    products: [
      {
        _id: randomBytes(12).toString('hex'),
        ...initProduct
      },
      {
        _id: randomBytes(12).toString('hex'),
        ...initProduct
      },
    ]
  }

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      title: '',
      sections: [ initBannerSectionValue, initImageProductSectionsValue, initTextProductSectionsValue ]
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields, move, append, remove, update } = useFieldArray({ name: 'sections', control });

  const data = watch();
  const sections = watch('sections');

  const uploadProductExcel = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let products = [];

      for (let index = 1; index < data.length; index++) {
        const element = data[index];
        
        let barcode_no = element[0] || '';
        let name = element[1] || '';
        let spec = element[2] || '';
        let normal_price =(element[3] || '0').replace(/[^0-9]/g,'');
        let price = (element[4] || '0').replace(/[^0-9]/g,''); 
        let option = element[5] || '';
        let image = element[0] ? `https://ms.martshop.co.kr/mart-image/${element[0]}` : '' 

        products.push({
          _id: randomBytes(12).toString('hex'),
          barcode_no,
          name,
          spec,
          normal_price,
          price,
          option,
          image,
          in_stock: true
        })
      }

      setValue(`sections.${e.target.id}.products`, [...sections[e.target.id].products, ...products]);
      
      e.target.value = null;
    };
    reader.readAsBinaryString(file);
  };

  const appendProduct = (sectionIndex) => {
    setValue(`sections.${sectionIndex}.products`, [...sections[sectionIndex].products, {
      ...initProduct,
      _id: randomBytes(12).toString('hex')
    }]);

    getRef(`section.${sectionIndex}.product.${sections[sectionIndex].products.length - 2}`).current.scrollIntoView({ behavior: 'smooth' })
  }

  const removeProduct = (sectionIndex, productIndex) => {
    sections[sectionIndex].products.splice(productIndex, 1);

    setValue(`sections.${sectionIndex}.products`, sections[sectionIndex].products);
  }

  const moveFirstProduct = (sectionIndex, productIndex) => {
    let element = sections[sectionIndex].products[productIndex];
    sections[sectionIndex].products.splice(productIndex, 1);
    sections[sectionIndex].products.splice(0, 0, element);

    setValue(`sections.${sectionIndex}.products`, sections[sectionIndex].products);
  }

  const moveLeftProduct = (sectionIndex, productIndex) => {
    let element = sections[sectionIndex].products[productIndex];
    sections[sectionIndex].products.splice(productIndex, 1);
    sections[sectionIndex].products.splice(productIndex-1, 0, element);
    
    setValue(`sections.${sectionIndex}.products`, sections[sectionIndex].products);
  }

  const moveRightProduct = (sectionIndex, productIndex) => {
    let element = sections[sectionIndex].products[productIndex];
    sections[sectionIndex].products.splice(productIndex, 1);
    sections[sectionIndex].products.splice(productIndex+1, 0, element);
    
    setValue(`sections.${sectionIndex}.products`, sections[sectionIndex].products);
  }

  const moveLastProduct = (sectionIndex, productIndex) => {
    let lastIndex = sections[sectionIndex].products.length;
    let element = sections[sectionIndex].products[productIndex];
    sections[sectionIndex].products.splice(productIndex, 1);
    sections[sectionIndex].products.splice(lastIndex, 0, element);
    
    setValue(`sections.${sectionIndex}.products`, sections[sectionIndex].products);
  }

  const onSubmit = (data) => {
    var errorCnt = 0;
    for (let index = 0; index < data.sections.length; index++) {
      const element = data.sections[index];

      if(element.type === 'BannerImage' && !element?.title_image){
        setError(`sections.${index}.title_image`,{message: '배너를 선택해 주세요'});
        errorCnt ++;
      }
    }

    if(errorCnt > 0){
      return;
    }

    dispatch(createLeaflet({
      ...data,
      from_date: `${data.from_date} ${data.from_date_time ? data.from_date_time : '00:00:00'}`,
      to_date: `${data.to_date} ${data.to_date_time ? data.to_date_time : '23:59:59'}`
    }))
    .then((res) => {
      toast.success('새로운 전단지를 만들었습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      history.navigate(`/manager/leaflet/${res.id}/modify`, {replace: true})
    })
    .catch(message => {
      console.log('message')
    })
  }

  const onUploadSectionImage = (e) => {
    const img = e.target.files[0];

    const formData = new FormData();
    formData.append('image', img);

    dispatch(uploadImage(formData))
    .then(res=>{
      setValue(`sections.${e.target.id}.title_image`, `${config.API_SERVER_HOST}/files/${res.filename}`);
      setError(`sections.${e.target.id}.title_image`, {message: ''});
    })
    .catch(err=>console.log(err))
    e.target.value = null;
  }

  const onUploadProductImage = (e) => {
    const img = e.target.files[0];

    const formData = new FormData();
    formData.append('image', img);

    dispatch(uploadImage(formData))
    .then(res=>{
      const sectionId = e.target.id.split('_')[0]
      const productId = e.target.id.split('_')[1]

      setValue(`sections.${sectionId}.products.${productId}.image`, `${config.API_SERVER_HOST}/files/${res.filename}`);
    })
    .catch(err=>console.log(err))
    e.target.value = null;
  }

  const scrollToTop = () => {
    getRef('top-screen').current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToBottom = () => {
    getRef('bottom-screen').current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if(showGetProductImageModal || showGetProductModal){
      getMartSampleData();
    }
  }, [currentPage]);

  const getMartSampleData = (callback) => {
    dispatch(setIsLoading(true));
    dispatch(martSampleData({
      page: currentPage,
      name: searchProductName
    }))
    .then(res => {
      setCurrentItems(res.docs);
      setTotalItemCount(res.total_cnt);
      setTotalPageCount(res.total_cnt / 18)
      dispatch(setIsLoading(false));
      callback()
    })
    .catch(e=>{
      dispatch(setIsLoading(false));
    })
  }

  const getProductImage = (sectionIndex, productIndex) => {
    setSelectedItemIndex({sectionIndex, productIndex})
    setSearchProductName('');
    setCurrentPage(1);
    getMartSampleData(()=>{
      setShowGetProductImageModal(true); 
    });
  }

  const getProduct = (sectionIndex, productIndex) => {
    setSelectedItemIndex({sectionIndex, productIndex})
    setSearchProductName('');
    setCurrentPage(1);
    getMartSampleData(()=>{
      setShowGetProductModal(true);
    });
  }

  const getBannerImage = (sectionIndex) => {
    setSelectedItemIndex({sectionIndex})
    setSelectedBannerVal('');
    setShowGetBannerImageModal(true); 
  }

  const getCornerImage = (sectionIndex) => {
    setSelectedItemIndex({sectionIndex})
    setShowGetCornerImageModal(true); 
  }

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };
  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <section className='content'>
          <div className='container container_create container_createbb'>
            <div className='row flex_box_create'>
              <div className='box_wx'>
                  <div className='new_box_s' ref={setRef(`top-screen`)}>
                    <div className="box_title font_7">
                      모바일 전단 만들기
                    </div>
                    <div className='card-body'>
                      <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                        <label htmlFor='title'>행사명</label>
                        <input type='text' className='form-control' id={`title`} name={`title`} {...register(`title`)} placeholder='7월 18일 전단지' />
                        <div className='invalid-feedback'>{errors.title?.message}</div>
                      </div>
                      <div className="form-row_new">
                        <div className={`form-group ${errors.from_date ? 'is-invalid' : ''}`}>
                          <label htmlFor='from_date'>주문 시작일</label>
                          <input type='date' className='form-control' id={`from_date`} name={`from_date`} {...register(`from_date`)} />
                          <div className='invalid-feedback'>{errors.from_date?.message}</div>
                        </div>
                        <div className={`form-group ${errors.from_date ? 'is-invalid' : ''}`}>
                          <label htmlFor='from_date'>주문 시작 시간</label>
                          <input type='time' className='form-control' id={`from_date_time`} name={`from_date_time`} {...register(`from_date_time`)} />
                          <div className='invalid-feedback'>{errors.from_date_time?.message}</div>
                        </div>
                        <div className={`form-group ${errors.from_date ? 'is-invalid' : ''}`}>
                          <label htmlFor='from_date'> </label> 
                          <div className=''>~</div> 
                        </div>
                        <div className={`form-group ${errors.to_date ? 'is-invalid' : ''}`}>
                          <label htmlFor='from_date'>주문 종료일</label>
                          <input type='date' className='form-control' id={`to_date`} name={`to_date`} {...register(`to_date`)}/>
                          <div className='invalid-feedback'>{errors.to_date?.message}</div>
                        </div>
                        <div className={`form-group ${errors.to_date ? 'is-invalid' : ''}`}>
                          <label htmlFor='from_date'>주문 종료 시간</label>
                          <input type='time' className='form-control' id={`to_date_time`} name={`to_date_time`} {...register(`to_date_time`)}/>
                          <div className='invalid-feedback'>{errors.to_date_time?.message}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    fields.map((item, i)=>(
                      <div className='new_box_s' ref={setRef(item.id)} key={item.id}>
                        <div className='card-header'>
                          {/* <button type='button'>엑셀 샘플 다운로드</button>
                          <button type='button'>상품 엑셀 업로드</button>
                          <span>상품을 일괄업로드 하시려면 엑셀 샘플 다운로드하여 작성 후 업로드 해주시면 됩니다</span>
                          <button type='button'>엑셀 샘플 다운로드</button>
                          <button type='button'>상품 엑셀 업로드</button>
                           */}
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <h3 className='card-title' style={{lineHeight: '21.25px'}}>{item.type === 'ImageProductItem' ? '이미지형 상품' : item.type === 'TextProductItem' ? '텍스트형 상품' : '배너'}</h3>
                            </div>
                            <div>
                            {
                                item.type === 'ImageProductItem' ?
                                (
                                  <>
                                    {
                                      i !== 0 && (<button type='button' className='btn btn-arrow-upx ml-2' onClick={()=>{
                                        move(i, i-1);
                                        setTimeout(()=>{
                                          getRef(item.id).current.scrollIntoView({ behavior: 'smooth' })
                                        })
                                      }}></button>)
                                    }
                                    { 
                                      fields.length -1 !== i && (<button type='button' className='btn btn-arrow-downx  ml-2' onClick={()=>{
                                        move(i, i+1);
                                        setTimeout(()=>{
                                          getRef(item.id).current.scrollIntoView({ behavior: 'smooth' })
                                        })
                                      }}></button>)
                                    }

                                  </>
                                ) : (
                                  <>
                                    {
                                      i !== 0 && (<button type='button' className='btn btn-arrow-upx  ml-2' onClick={()=>{
                                        move(i, i-1)
                                        setTimeout(()=>{
                                          getRef(item.id).current.scrollIntoView({ behavior: 'smooth' })
                                        })
                                      }}></button>)
                                    }
                                    { 
                                      fields.length -1 !== i && (<button type='button' className='btn btn-arrow-downx ml-2' onClick={()=>{
                                        move(i, i+1)
                                        setTimeout(()=>{
                                          getRef(item.id).current.scrollIntoView({ behavior: 'smooth' })
                                        })
                                      }}></button>)
                                    }
                                  </>
                                )
                              }
                              <button type='button' className='btn btn-sm btn-danger' onClick={()=>remove(i)}>삭제</button>
                            </div>
                        </div>
                        </div>
                        <div className='card-body'>
                          {
                            item.type !== 'BannerImage' &&
                            (
                              <>
                                <div className='row select_www'>
                                  <div className='col-3 d-flex align-items-center mb-2'>
                                    <label htmlFor={`sections.${i}.title_option.0`} className='d-block w-100 ml-2 mb-0 check_c'>
                                    <input type='radio' 
                                      id={`sections.${i}.title_option.0`} 
                                      value={'text'} 
                                      name={`sections.${i}.title_option`} 
                                      className={'chk-default'}
                                      {...register(`sections.${i}.title_option`)}
                                      checked={'text' == sections[i].title_option}
                                      onChange={e=> {
                                        setValue(`sections.${i}.title_option`, e.target.value);  
                                        setValue(`sections.${i}.title_text`, '오늘의 특가 ⏰'); 
                                        setValue(`sections.${i}.title_image`, '')
                                      }}/>
                                      <span>텍스트형 배너</span>
                                    </label>
                                  </div>
                                  <div className='col-3 d-flex align-items-center mb-2'>
                                    <label htmlFor={`sections.${i}.title_option.1`} className='d-block w-100 ml-2 mb-0 check_c'>
                                    <input type='radio' 
                                      id={`sections.${i}.title_option.1`} 
                                      value={'image'} 
                                      name={`sections.${i}.title_option`} 
                                      className={'chk-default'}
                                      {...register(`sections.${i}.title_option`)}
                                      checked={'image' == sections[i].title_option}
                                      onChange={e=> {
                                        setValue(`sections.${i}.title_option`, e.target.value);  
                                        setValue(`sections.${i}.title_text`, ''); 
                                        setValue(`sections.${i}.title_image`, '')
                                      }}/>
                                      <span>이미지형 배너</span>
                                    </label>
                                  </div>
                                  <div className='col-3 d-flex align-items-center mb-2'>
                                    <label htmlFor={`sections.${i}.title_option.2`} className='d-block w-100 ml-2 mb-0 check_c'>
                                    <input type='radio' 
                                      id={`sections.${i}.title_option.2`} 
                                      value={'none'} 
                                      name={`sections.${i}.title_option`} 
                                      className={'chk-default'}
                                      {...register(`sections.${i}.title_option`)}
                                      checked={'none' == sections[i].title_option}
                                      onChange={e=> {
                                        setValue(`sections.${i}.title_option`, e.target.value);  
                                        setValue(`sections.${i}.title_text`, ''); 
                                        setValue(`sections.${i}.title_image`, '')
                                      }}/>
                                      <span>배너 없음</span>
                                    </label>
                                  </div>

                                  <div className="select_h">
                                    <select
                                      className='ml-2'
                                      id={`sections[${i}]product_item_column_count`}
                                      name={`sections[${i}]product_item_column_count`}
                                      {...register(`sections.${i}.product_item_column_count`)}>
                                      <option value='1'>1열</option>
                                      <option value='2'>2열</option>
                                      <option value='3'>3열</option>
                                      <option value='4'>4열</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='row mb-3'>
                                {
                                  sections[i].title_option === 'text' ? (<>
                                  <div className={`col-5ww ${errors.sections?.[i]?.title_text ? 'is-invalid' : ''}`}>
                                          <input type='text' className='form-control mr-1' id={`sections[${i}]title_text`} name={`sections[${i}]title_text`} {...register(`sections.${i}.title_text`)} placeholder='코너명을 입력해 주세요.' maxLength={20}/>
                                          <div className='invalid-feedback'>{errors.sections?.[i]?.title_text?.message}</div>
                                        </div>
                                  </>) : sections[i].title_option === 'image' ? (<div className='col-12 position-relative'>
                                            {sections[i].title_image ? (<img src={`${sections[i].title_image}`} width='100%'/>) : (<img src={`/images/no_bannerImageBig.svg`} width='100%'/>)} 
                                            <div className='position-absolute d-flex justify-content-center align-items-center cont_pp'>
                                              <button type='button' className='btn btn_black_Fw btn_ww' onClick={()=>getRef(`section.${i}.file`).current.click()}>내 사진 등록</button>
                                              <button type='button' className='btn btn_blue2 ml-3 btn_ww' onClick={()=>getCornerImage(i)}>제공 이미지</button>
                                            </div>
                                            <input type='file' className='position-absolute' ref={setRef(`section.${i}.file`)} id={i} accept='image/png, image/gif, image/jpeg'  onChange={onUploadSectionImage} style={{width:0}}/>
                                    </div>) : <></>
                                }
                              </div>
                              <div className='row mb-3 ppooo'>
                                <div className='col-12 d-flex flex-row justify-content-end '>
                                  <input type='file' onChange={uploadProductExcel} id={`${i}`} accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' ref={setRef(`excel_upload_section_${i}`)}  style={{display:'none'}}/>
                                  <Link to='/sample/product_sample.xls' target='_blank' className='btn btn-xx1' download>엑셀 샘플 다운로드</Link>
                                  &nbsp;
                                  <button type='button' onClick={()=>getRef(`excel_upload_section_${i}`).current.click()} className='btn btn-xx2'>상품 엑셀 업로드</button>
                                </div>
                              </div>
                              </>
                            )
                          }
                          <div className='row'>
                            {
                              item.type === 'ImageProductItem' ? (
                                <div className='col-12'>
                                  <div className='row'>
                                    {
                                      sections[i].products.map((product,p_index)=>(
                                        <div className='ww22z' key={product.id} ref={setRef(`section.${i}.product.${p_index}`)}>
                                          <div className='row'>
                                            <div className='w33 text-center'>
                                              <div className='square border mb-2'>
                                                <div className='inner'>
                                                  <img src={product.image} className='mb-2' onError={e=>e.target.src='/images/no_productImage.svg'} style={{objectFit: 'cover'}}/>
                                                </div>
                                                {
                                                  // product.normal_price > 0 && product.price > 0  && (<div className='ribbon-wrapper'>
                                                  //   <div className='ribbon bg-red'>
                                                  //     {`${100 - Math.round(product.price / product.normal_price * 100)}%`} 할인
                                                  //   </div>
                                                  // </div>)
                                                }
                                              </div>
                                              <div className='mb-2'>
                                                <input type='file' className='position-absolute d-none' id={`${i}_${p_index}`} ref={setRef(`sections.${i}.products.${p_index}.image`)} accept='image/png, image/gif, image/jpeg'  onChange={onUploadProductImage}/>
                                                {/* <button type='button' className='btn btn-md btn-default' onClick={()=>getRef(`sections.${i}.products.${p_index}.image`).current.click()}>사진교체</button> */}
                                                <div className='btn-group-vertical  w-100' role='group' aria-label='Basic example'>
                                                  <button type='button' className='btn btn_black_Fw btnxc mb-1' onClick={()=>getRef(`sections.${i}.products.${p_index}.image`).current.click()}>내 사진 등록</button>
                                                  {/* <button type='button' className='btn btn-sm btn-default mb-1' onClick={getProductImage}>최근상품</button> */}
                                                  <button type='button' className='btn btn_blue2 btnxc mb-1' onClick={()=>getProductImage(i, p_index)}>제공 이미지</button>
                                                  <button type='button' className='btn btn_basic btnxc mb-1' onClick={()=>getProduct(i, p_index)}>상품 정보 가져오기</button>
                                                </div>
                                              </div>
                                              <div className="btnnnn">
                                              {
                                              p_index !== 0 && (
                                              <>
                                              <button type='button' className='btn btn-sm btn-default' onClick={()=>moveFirstProduct(i, p_index)}><i className='fas fa-angle-double-left'></i></button>
                                              <button type='button' className='btn btn-sm btn-default' onClick={()=>moveLeftProduct(i, p_index)}><i className='fas fa-angle-left'></i></button>
                                              </>
                                              )}
                                              <button type='button' className='btn btn-sm btn-default' onClick={()=>removeProduct(i, p_index)}><i className='fas fa-minus'></i></button>
                                              {
                                              p_index !== item.products.length - 1 && (
                                              <>
                                              <button type='button' className='btn btn-sm btn-default' onClick={()=>moveRightProduct(i, p_index)}><i className='fas fa-angle-right'></i></button>
                                              <button type='button' className='btn btn-sm btn-default' onClick={()=>moveLastProduct(i, p_index)}><i className='fas fa-angle-double-right'></i></button>
                                              </>
                                              )}
                                              </div>
                                            </div>
                                            <div className='w77'>
                                              <div className={`form-group ${errors.sections?.[i]?.products?.[p_index]?.name?.message ? 'is-invalid' : ''}`}>
                                                <label htmlFor='exampleInputEmail1'>상품명</label>
                                                <input type='text' className='form-control' name={`sections.${i}.products.${p_index}.name`} {...register(`sections.${i}.products.${p_index}.name`)} placeholder='상품명' />
                                                <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.name?.message}</div>
                                              </div>
                                              <div className={`form-group ${errors.sections?.[i]?.products?.[p_index]?.spec?.message ? 'is-invalid' : ''}`}>
                                                <label htmlFor='exampleInputEmail1'>규격</label>
                                                <input type='text' className='form-control' name={`sections.${i}.products.${p_index}.spec`} {...register(`sections.${i}.products.${p_index}.spec`)} placeholder='규격' />
                                                <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.spec?.message}</div>
                                              </div>
                                              <div className="mm_ul">
                                                <ul>
                                                  <li>
                                                    <div className={`form-group ${errors.sections?.[i]?.products?.[p_index]?.normal_price?.message ? 'is-invalid' : ''}`}>
                                                      <label htmlFor='exampleInputEmail1'>정상가</label>
                                                      <input type='number' className='form-control'  min={0} max={100000000} name={`sections.${i}.products.${p_index}.normal_price`} {...register(`sections.${i}.products.${p_index}.normal_price`,{
                                                        onChange: (e)=>{
                                                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                                                        if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                                                          e.target.value = e.target.value.substring(1,e.target.value.length)
                                                        }
                                                      }})} placeholder='4000' />
                                                      <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.normal_price?.message}</div>
                                                    </div>
                                                  </li>
                                                  <li>
                                                    <div className={`form-group ${errors.sections?.[i]?.products?.[p_index]?.price?.message ? 'is-invalid' : ''}`}>
                                                      <label htmlFor='exampleInputEmail1'>행사가</label>
                                                      <input type='number' className='form-control'  min={1} max={100000000} name={`sections.${i}.products.${p_index}.price`} {...register(`sections.${i}.products.${p_index}.price`,{
                                                        onChange: (e)=>{
                                                        e.target.value = e.target.value.replace(/[^0-9]/g,'');
                                                        if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                                                          e.target.value = e.target.value.substring(1,e.target.value.length)
                                                        }
                                                      }})} placeholder='3000' />
                                                      <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.price?.message}</div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>


                                              <div className='form-group'>
                                                  <Checkbox id={`sections.${i}.products.${p_index}.in_stock`} name={`sections.${i}.products.${p_index}.in_stock`} register={register} checked={sections?.[i]?.products?.[p_index]?.in_stock}>
                                                  재고 있음
                                                  </Checkbox>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      ))
                                    }
                                  </div>
                                  <div className='text-center'>
                                    <button type='button' className='btn btn_black_Fw ww100' onClick={()=>appendProduct(i)}>상품 추가</button>
                                  </div>
                                </div>
                                ) : item.type === 'TextProductItem' ? (
                                <div className='col-12'>
                                  <table className='table table-stylex'>
                                    <thead>
                                      <tr>
                                        <th>상품명</th>
                                        <th>규격</th>
                                        <th>정상가</th>
                                        <th>행사가</th>
                                        <th className="we1">재고</th>
                                        <th className="we2">삭제</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        sections[i].products.map((product,p_index)=>(
                                          <tr key={product.id} ref={setRef(`section.${i}.product.${p_index}`)}>
                                            <td className={`${errors.sections?.[i]?.products?.[p_index]?.name?.message ? 'is-invalid' : ''}`}>
                                              <input type='text' className='form-control' name={`sections.${i}.products.${p_index}.name`} {...register(`sections.${i}.products.${p_index}.name`)} placeholder='상품명' />
                                              <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.name?.message}</div>
                                            </td>
                                            <td className={`${errors.sections?.[i]?.products?.[p_index]?.spec?.message ? 'is-invalid' : ''}`}>
                                              <input type='text' className='form-control' name={`sections.${i}.products.${p_index}.spec`} {...register(`sections.${i}.products.${p_index}.spec`)} placeholder='규격' />
                                              <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.spec?.message}</div>
                                            </td>
                                            <td className={`${errors.sections?.[i]?.products?.[p_index]?.normal_price?.message ? 'is-invalid' : ''}`}>
                                              <input type='number' className='form-control'  min={0} name={`sections.${i}.products.${p_index}.normal_price`} {...register(`sections.${i}.products.${p_index}.normal_price`,{
                                                  onChange: (e)=>{
                                                  e.target.value = e.target.value.replace(/[^0-9]/g,'');
                                                  if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                                                    e.target.value = e.target.value.substring(1,e.target.value.length)
                                                  }
                                                }})} placeholder='정상가' />
                                              <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.normal_price?.message}</div>
                                            </td>
                                            <td className={`${errors.sections?.[i]?.products?.[p_index]?.price?.message ? 'is-invalid' : ''}`}>
                                              <input type='number' className='form-control' min={1} name={`sections.${i}.products.${p_index}.price`} {...register(`sections.${i}.products.${p_index}.price`,{
                                                  onChange: (e)=>{
                                                  e.target.value = e.target.value.replace(/[^0-9]/g,'');
                                                  if(e.target.value.length > 0 && e.target.value.substring(0,1) === '0') {
                                                    e.target.value = e.target.value.substring(1,e.target.value.length)
                                                  }
                                                }})} placeholder='행사가' />
                                              <div className='invalid-feedback'>{errors.sections?.[i]?.products?.[p_index]?.price?.message}</div>
                                            </td>
                                            <td style={{verticalAlign:'middle'}}>
                                              <Checkbox id={`sections.${i}.products.${p_index}.in_stock`} name={`sections.${i}.products.${p_index}.in_stock`} register={register} checked={sections?.[i]?.products?.[p_index]?.in_stock}>
                                                  재고 있음
                                              </Checkbox>
                                            </td>
                                            <td>
                                              <button type='button' className='btn btn-close' onClick={()=>removeProduct(i, p_index)}></button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                  <div className='text-center'>
                                    <button type='button' className='btn btn_black_Fw ww100' onClick={()=>appendProduct(i)}>상품 추가</button>
                                  </div>
                                </div>
                              ) : (<>
                                  <div className='col-12'>
                                    <div className={`position-relative ${errors.sections?.[i]?.title_image?.message ? 'is-invalid' : ''}`}>
                                      {sections[i].title_image ? (<img src={`${sections[i].title_image}`} width='100%'/>) : (<img src={`/images/no_bannerImageBig.svg`} width='100%'/>)} 
                                      <div className='position-absolute d-flex justify-content-center align-items-center cont_pp'>
                                        <button type='button' className='btn btn_black_Fw btn_ww' onClick={()=>getRef(`section.${i}.file`).current.click()}>내 사진 등록</button>
                                        <button type='button' className='btn btn_blue2 btn_ww ml-3' onClick={()=>getBannerImage(i)}>제공 이미지</button>
                                      </div>
                                      <input type='file' className='position-absolute' ref={setRef(`section.${i}.file`)} id={i} accept='image/png, image/gif, image/jpeg'  onChange={onUploadSectionImage} style={{width:0}}/>
                                      <div className='invalid-feedback'>{errors.sections?.[i]?.title_image?.message}</div>
                                    </div>
                                  </div>
                                </>)
                              }
                          </div>
                        </div>
                      </div>
                    ))
                  }

             </div>
             <div className='box_wx2 d-xs-none'>
              <div className='new_box_s sticky-top' style={{height: 'calc(100vh - 55px)'}}>
                <div className='card-header-Preview'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div>
                      <h3 className='card-title' style={{lineHeight: '21.25px'}}>미리보기</h3>
                    </div>
                  </div>
                </div>
                <div className='card-body-Preview' style={{height: 'calc(100% - 60px)',overflowY: 'auto'}}>
                  <section className='body_container'>
                      {sections.map((item,i)=>(
                        <div key={item.id}>
                          {
                            item.type === 'BannerImage' ? (<div className={i !== 0 ? 'mt-3 bbbnx' : 'bbbn'}>
                              {
                                item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/no_bannerImage.svg`} width='100%'/>)
                              }
                            </div>) : item.type==='ImageProductItem' ? (<>
                              <div className='row mt-3 bbbn'>                                  
                                {
                                  item.title_option === 'image' ? (
                                    <div className='col-12'>
                                      {item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/no_bannerImage.svg`} width='100%'/>)}
                                    </div>
                                  ) : item.title_option === 'text' ? (
                                    <div className='section_title'>{sections[i].title_text || '새코너'}</div>
                                  ) : <></>
                                }
                              </div>
                              <div className='row mt-3 lopp'>
                                { 
                                  sections[i].products.map((product, p_index)=>(
                                    <div className={`col-${12 / sections[i].product_item_column_count} product_text`} key={product.id}>
                                      <div className='square border mb-2 product_img'>
                                        <div className='inner'>
                                          <img src={product.image} className='mb-2' onError={e=>e.target.src='/images/no_productImage.svg'} style={{objectFit: 'cover'}}/>
                                        </div>
                                        {/* {
                                          product.normal_price > 0 && product.price > 0  && (<div className='ribbon-wrapper'>
                                            <div className='ribbon bg-red'>
                                              {`${100 - Math.round(product.price / product.normal_price * 100)}%`}
                                            </div>
                                          </div>)
                                        } */}
                                      </div>
                                      <p className='tx1'>{product.name || '새상품'}</p>
                                      {product.spec && (<p className='tx2'>{product.spec}</p>)}
                                      <div className='p_discount'>
                                        {product.in_stock ? (
                                          <div className="inner_cont">
                                            <div className="inner_1">판매가 {product.normal_price>0 && <span className='tx5'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}</div>
                                            <div className="inner_2">행사가 <span className='tx3'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span><span className='t1'></span></div>
                                          </div>
                                        ) : (<p className='tx4'>재고없음</p>)}
                                      </div>
                                    </div>
                                  ))
                                }
                            </div>
                            </>) : (<>
                              <div className='row'>                                  
                                  {/* { item.title_image ? <img src={`${item.title_image}`} width='100%'/> : <div className='section_title'>{sections[i].title_text || '새코너'}</div>} */}
                                  {
                                    item.title_option === 'image' ? (
                                      <div className='col-12 bbbn'>
                                        {item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/no_bannerImage.svg`} width='100%'/>)}
                                      </div>
                                    ) : item.title_option === 'text' ? (
                                      <div className='section_title'>{sections[i].title_text || '새코너'}</div>
                                    ) : <></>
                                  }
                              </div>
                              <div className='col-12 text_product_list_wrap mt-3 bbbnw'>
                              { 
                                sections[i].products.map(product=>(  
                                <div className='text_product_list_item'>
                                  <div className='text_group'>
                                    <span className='name'>{product.name || '새상품'}</span>   
                                    <span className='spec'>{product.spec}</span> 
                                  </div>
                                  <div className='price_group'>
                                    {product.normal_price>0 && <span className='price1'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                                    {product.in_stock ? <span className='price2'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : <span className='outstock'>재고없음</span>}               
                                  </div>
                                </div>
                                  ))}
                              </div>
                            </>)
                          }
                        </div>
                      ))}
                  </section>
                  {/* <div className='footer_wrap mt-3'>
                    <div className="footer_top_area">
                      <a href="/bbs/term.php?gubun=terms">서비스 이용약관</a>
                      &nbsp;&nbsp;<span>l</span>&nbsp;&nbsp;
                      <a href="/bbs/term.php?gubun=privacy">개인정보 처리방침 및 수집 이용동의</a>
                    </div>
                      상호: (주)칼라애드 &nbsp;│&nbsp; 주소: 광주광역시 동구 학소로 139, 3층(학동)<br />
                      대표: 조원주 &nbsp;│&nbsp; 사업자등록번호: 369-88-01037 &nbsp;│&nbsp; Tel. 062-228-9200<br />
                      개인정보 보호책임자: 김세진   
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='fixed-bottom d-flex justify-content-center'>
        <div className="fixed_bottom_color">
          <div className='left_fixed' ref={setRef(`bottom-screen`)}>
            <button type='button' className='btn btn-primary' onClick={()=>{
              append({
                ...initBannerSectionValue,
                _id: randomBytes(12).toString('hex'),
              });
              setTimeout(()=>scrollToBottom());
            }}>배너 추가</button> &nbsp;
            <button type='button' className='btn btn-primary' onClick={()=>{
              append({
                ...initImageProductSectionsValue,
                _id: randomBytes(12).toString('hex'),
                products: [
                  {
                    ...initProduct,
                    _id: randomBytes(12).toString('hex'),
                  },
                  {
                    ...initProduct,
                    _id: randomBytes(12).toString('hex'),
                  }
                ]
              });
              setTimeout(()=>scrollToBottom());
            }}>이미지 상품 추가</button> &nbsp;
            <button type='button' className='btn btn-primary' onClick={()=>{
              append({
                ...initTextProductSectionsValue,
                _id: randomBytes(12).toString('hex'),
                products: [
                  {
                    ...initProduct,
                    _id: randomBytes(12).toString('hex'),
                  },
                  {
                    ...initProduct,
                    _id: randomBytes(12).toString('hex'),
                  }
                ]
              });
              setTimeout(()=>scrollToBottom(),100);
            }}>텍스트 상품 추가</button> &nbsp;
          </div>

          <div className='d-flex justify-content-center right_fixed'>
            <button type='submit' className='btn btn-primary2'>저장</button>
            <Link to='/manager/leaflet/list' className='btn btn-primary3 ml-2'>목록</Link>
            <button type='button' className='btn btn-arrow-up ml-2' onClick={()=>scrollToTop()}></button>
            <button type='button' className='btn btn-arrow-down ml-2' onClick={()=>scrollToBottom()}></button>
          </div>
        </div>
      </div>
      </form>

      <Modal show={showGetProductImageModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered onHide={()=>setShowGetProductImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>이미지 가져오기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='form-row align-items-center'>
                <div className='col-auto'>
                  <label className='sr-only' htmlFor='inlineFormInput'>상품명</label>
                  <input type='text' className='form-control mb-2' id='name' placeholder='상품명을 입력해주세요.' onChange={e=>{
                    setSearchProductName(e.target.value);
                  }}/>
                </div>
                <div className='col-auto'>
                  <button type='button' className='btn btn-primary mb-2' onClick={()=>{
                    setCurrentPage(1);
                    getMartSampleData();
                  }}>검색</button>
                </div>
              </div>
            </div>
          </div>
          {currentItems && (<div className='row '>
            {currentItems.map(item=>(<div className='col-2 text-center'>
              <img className='border' src={`https://img.selgo.kr/file/product/thumb1/thumb_1_${item.filename}`}  style={{height: '115px', cursor:'pointer'}} 
                onClick={()=>{
                const sectionId = selectedItemIndex.sectionIndex
                const productId = selectedItemIndex.productIndex

                setValue(`sections.${sectionId}.products.${productId}.image`, `https://img.selgo.kr/file/product/thumb1/thumb_1_${item.filename}`);

                setShowGetProductImageModal(false);
            }}/>
              <p className='mt-2' style={{fontSize:'13px', lineHeight: '15px', overflow:'hidden', textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>{item.name}</p>
            </div>))}
          </div>)}
          <div className='paging text-center mt-4'>
            <ReactPaginate
              breakLabel='...'
              nextLabel={<span className='paging_end ml30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first mr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName='paging_num paging_current'
              pageLinkClassName='paging_num'
              breakLinkClassName='paging_num'
              previousLinkClassName='paging_num'
              nextLinkClassName='paging_num'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-primary mt-auto mb-auto ml-2' onClick={()=>setShowGetProductImageModal(false)}><i className='fas fa-close'></i> 닫기</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGetProductModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered onHide={()=>setShowGetProductModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>상품 가져오기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-3'>
            <div className='col-12'>
              <div className='form-row align-items-center'>
                <div className='col-auto'>
                  <label className='sr-only' htmlFor='inlineFormInput'>상품명</label>
                  <input type='text' className='form-control mb-2' id='name' placeholder='상품명을 입력해주세요.' onChange={e=>{
                    setSearchProductName(e.target.value);
                  }}/>
                </div>
                <div className='col-auto'>
                  <button type='button' className='btn btn-primary mb-2' onClick={()=>{
                    setCurrentPage(1);
                    getMartSampleData();
                  }}>검색</button>
                </div>
              </div>
            </div>
          </div>
          {currentItems && (<div className='row '>
            {currentItems.map(item=>(<div className='col-2 text-center'>
              <img className='border' src={`https://img.selgo.kr/file/product/thumb1/thumb_1_${item.filename}`} onClick={()=>{
                const sectionId = selectedItemIndex.sectionIndex
                const productId = selectedItemIndex.productIndex

                setValue(`sections.${sectionId}.products.${productId}.image`, `https://img.selgo.kr/file/product/thumb1/thumb_1_${item.filename}`);
                setValue(`sections.${sectionId}.products.${productId}.name`, item.name);
                setValue(`sections.${sectionId}.products.${productId}.barcode_no`, item.barcode);

                setShowGetProductModal(false);
              }}/>
              <p className='mt-2' style={{fontSize:'13px', lineHeight: '15px', overflow:'hidden', textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>{item.name}</p>
            </div>))}
          </div>)}
          <div className='paging text-center mt-4'>
            <ReactPaginate
              breakLabel='...'
              nextLabel={<span className='paging_end ml30'></span>}
              onPageChange={handlePageClick}
              forcePage={ currentPage - 1 }
              pageRangeDisplayed={3}
              pageCount={totalPageCount}
              previousLabel={<span className='paging_first mr30'></span>}
              renderOnZeroPageCount={null}
              activeLinkClassName='paging_num paging_current'
              pageLinkClassName='paging_num'
              breakLinkClassName='paging_num'
              previousLinkClassName='paging_num'
              nextLinkClassName='paging_num'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-primary mt-auto mb-auto ml-2' onClick={()=>setShowGetProductModal(false)}><i className='fas fa-close'></i> 닫기</button>
        </Modal.Footer>
      </Modal>
      <Modal show={showGetBannerImageModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered onHide={()=>setShowGetBannerImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>이미지 가져오기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-check form-check-inline mb-3 check_c">
          <label htmlFor={`banner_image.0`} className='form-check-label model_check-label'>
          <input type='radio' id="banner_image.0" className="form-check-input" onChange={e=>setSelectedBannerVal(e.target.value)} value='' checked={selectedBannerVal === ''} />
          <span>전체</span>
          </label>
          {Object.keys(bannerData).map((key, i)=>
          <>
          <label htmlFor={`banner_image.${i+1}`} className='form-check-label model_check-label'>
          <input type='radio' id={`banner_image.${i+1}`} className="chk-default ml-3" onChange={e=>setSelectedBannerVal(e.target.value)} value={key} checked={selectedBannerVal === key} />
          <span>{bannerData[key].name}</span>
          </label>
          </>)}
        </div>
          <div className='row'>
            <div className='col-12' style={{height: '350px', overflowY:'auto'}}>
              <div className='row'>
                {selectedBannerVal ? (Array.from({length: bannerData[selectedBannerVal].item_count}, (_, i) => i + 1).map(f_index=>(<div className='col-2 text-center mb-1' key={f_index}>
                      <img className='border' src={`/images/banner/${selectedBannerVal}/${f_index}.jpg`} width="100%" height="112px" style={{cursor: 'pointer'}} onClick={()=>{
                        const sectionId = selectedItemIndex.sectionIndex
                            
                        setValue(`sections.${sectionId}.title_image`, `/images/banner/${selectedBannerVal}/${f_index}.jpg`);

                        setError(`sections.${sectionId}.title_image`,{message: ''});
        
                        setShowGetBannerImageModal(false);
                      }}/>
                    </div>))) : Object.keys(bannerData).map(key=>(<>
                  {
                    Array.from({length: bannerData[key].item_count}, (_, i) => i + 1).map(f_index=>(<div className='col-2 text-center mb-1' key={f_index}>
                      <img className='border' src={`/images/banner/${key}/${f_index}.jpg`} width="100%" height="112px" style={{cursor: 'pointer'}} onClick={()=>{
                        const sectionId = selectedItemIndex.sectionIndex
                            
                        setValue(`sections.${sectionId}.title_image`, `/images/banner/${key}/${f_index}.jpg`);

                        setError(`sections.${sectionId}.title_image`,{message: ''});
        
                        setShowGetBannerImageModal(false);
                      }}/>
                    </div>))
                  }
                </>))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-primary mt-auto mb-auto ml-2' onClick={()=>setShowGetBannerImageModal(false)}><i className='fas fa-close'></i> 닫기</button>
        </Modal.Footer>
      </Modal>
      <Modal show={showGetCornerImageModal} size='lg' aria-labelledby='contained-modal-title-vcenter' centered onHide={()=>setShowGetCornerImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>이미지 가져오기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12' style={{height: '250px', overflowY:'auto'}}>
              <div className='row'>
                {
                    cornerData.map(item=>(<div className='col-4 text-center mb-1' key={item.text}>
                      <img className='border' src={item.image} width="100%" style={{cursor: 'pointer'}} onClick={()=>{
                        const sectionId = selectedItemIndex.sectionIndex
                          
                        setValue(`sections.${sectionId}.title_image`, item.image);

                        setShowGetCornerImageModal(false);
                      }}/>
                    </div>))
                  }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-primary mt-auto mb-auto ml-2' onClick={()=>setShowGetCornerImageModal(false)}><i className='fas fa-close'></i> 닫기</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LeafletCreate;
