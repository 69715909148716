import api from '@/services/api';
import queryString from 'query-string';

class BoardService {
  createNotice(params) {
    return api.post('/boards/notice', params)
  }

  updateNotice(notice_id, params) {
    return api.put(`/boards/notice/${notice_id}`, params)
  }

  deleteNotice(notice_id) {
    return api.delete(`/boards//notice/${notice_id}`)
  }

  notices(params) {
    return api.get(`/boards/notice?${queryString.stringify(params)}`)
  }

  notice(notice_id) {
    return api.get(`/boards/notice/${notice_id}`)
  }

  createQuestion(params) {
    return api.post('/boards/question', params)
  }

  updateQuestion(question_id, params) {
    return api.put(`/boards/question/${question_id}`, params)
  }

  deleteQuestion(question_id) {
    return api.delete(`/boards/question/${question_id}`)
  }

  questions(params) {
    return api.get(`/boards/question?${queryString.stringify(params)}`)
  }

  question(question_id) {
    return api.get(`/boards/question/${question_id}`)
  }

  createAnswer(question_id, params) {
    console.log(question_id, params)
    return api.post(`/boards/question/${question_id}/answer`, params)
  }

  updateAnswer(question_id, answer_id, params) {
    return api.put(`/boards/question/${question_id}/answer/${answer_id}`, params)
  }

  deleteAnswer(question_id, answer_id) {
    return api.delete(`/boards/question/${question_id}/answer/${answer_id}`)
  }
}

export default new BoardService();
