
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createQuestion } from '@/actions/board';
import _ from 'lodash';
import FormCKEditor from '@/components/FormCKEditor';
import history from '@/helpers/history';
import { toast } from 'react-toastify';

function QuestionWrite() {
  const dispatch = useDispatch();
  
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const onCreateNotice = (event) => {
    if(!title) {
      toast.error('제목을 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } else if(!content) {
      toast.error('내용을 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } 
    
    const formData = new FormData();
    formData.append("title", title);
    formData.append("body", content);
    formData.append("attachment", selectedFile);

    dispatch(createQuestion(formData))
    .then(res => {
      history.navigate('/manager/support/qa/list', {replace: true});
    })
  };


  return (
    <>
      <section className="content pt-3">
        <div className="container new_box_s">
          <div className="box_title font_7">
            <input type="text" placeholder="제목을 입력해 주세요." onChange={e=>setTitle(e.target.value)}/>
            <div className="inner_1 font_3">
              <Link to={`/manager/support/qa/list`}>
                <button type="button" className="btn btn-success font_6">목록</button>
              </Link>
              <button type="button" className="btn btn-success font_6" style={{marginLeft: '5px'}} onClick={()=>onCreateNotice()}>글쓰기</button>
            </div>
          </div>        
          <div className="content">
            <FormCKEditor
              data={content}
              handleChange={setContent}
            />
          </div>
          <div style={{marginTop: '10px'}}>
            <h4>첨부파일</h4>
              <input
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default QuestionWrite;
