
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { question, deleteQuestion, createAnswer, updateAnswer, deleteAnswer } from '@/actions/board';
import _ from 'lodash';
import history from '@/helpers/history';
import dompurify from 'dompurify';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import config from '@/config';
import FormCKEditor from '@/components/FormCKEditor';

function QuestionView() {
  const dispatch = useDispatch();
  const params = useParams();
  const sanitizer = dompurify.sanitize;

  const currentUser = useSelector(state => state.user);
  const [currentItem, setCurrentItem] = useState(null);
  const [content, setContent] = useState('');

  useEffect(() => {
    dispatch(question(params.question_id))
    .then(res => {
      if(res?.comment?.text){
        setContent(res.comment.text)
      }

      setCurrentItem(res);
    })
    .catch(message=>{
      history.navigate('/manager/support/qa/list', {replace: true});
    })
  }, []);

  const onDeleteQuestion = (event) => {
    Swal.fire({
      text: '삭제 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteQuestion(params.question_id))
        .then(res => {
          toast.success('삭제 되었습니다..', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
          history.navigate('/manager/support/qa/list', {replace: true});
        })
      }
    })
  };

  const onCreateAnswer = () => {
    if(!content) {
      toast.error('답변 내용을 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } 
    dispatch(createAnswer(params.question_id, {
      text: content
    }))
    .then(res => {
      currentItem.comment = {
        _id: res.id,
        ...res
      };
      setCurrentItem({...currentItem})

      toast.success('답변이 등록되었습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
  }

  const onUpdateAnswer = () => {
    if(!content) {
      toast.error('답변 내용을 입력해 주세요.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      return;
    } 
    
    dispatch(updateAnswer(params.question_id, currentItem.comment._id, {
      text: content
    }))
    .then(res => {
      toast.success('답변이 수정되었습니다..', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    })
  }

  const onDeleteAnswer = () => {
    Swal.fire({
      text: '답변을 삭제 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: '아니요',
      confirmButtonText: '삭제'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAnswer(params.question_id, currentItem.comment._id))
        .then(res => {
          delete currentItem.comment;
          setContent('');
          setCurrentItem({...currentItem})

          toast.success('삭제 되었습니다..', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        })
      }
    })
  };

  const bytesToSize = (bytes) => { // 1
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  return (
    <>
      {currentItem && <section className="content pt-3">
        <div className="container new_box_s">
        <div className="box_title font_7">
          {currentItem.title}
          <div className="inner_1 font_3">
              <button type="button" className="btn btn-danger font_6" onClick={()=>onDeleteQuestion()}>삭제</button>
              <button type="button" className="btn btn-success font_6" onClick={()=>history.navigate(`/manager/support/qa/edit/${params.question_id}`, {replace: true})} style={{marginLeft: '5px'}}>수정</button>
              <button type="button" className="btn btn-success font_6" onClick={()=>history.navigate('/manager/support/qa/list', {replace: true})} style={{marginLeft: '5px'}}>목록</button>
            </div>
          </div>        
          <div dangerouslySetInnerHTML={{__html: sanitizer(currentItem.body)}} />
        </div>
        
        {currentItem.attachment && <div>
          <h4>첨부파일</h4>
          <Link to={`${config.API_SERVER_HOST}/boards/attachment/download/${currentItem.attachment.server_filename}`} target="_blank" download>{currentItem.attachment.original_filename}</Link>&nbsp;({bytesToSize(currentItem.attachment.size)})
        </div>}

        {currentUser.user.role === 'Admin' ? <div className='answer' style={{marginTop: '20px'}}>
          <h4>답변등록</h4>
          <FormCKEditor
            data={content}
            handleChange={setContent}
          />        
          <div style={{marginTop: '20px'}}>
            {currentItem.comment ? <>
              <button type="button" className="btn btn-danger font_6" onClick={()=>onDeleteAnswer()}>삭제</button>
              <button type="button" className="btn btn-success font_6" onClick={()=>onUpdateAnswer()}>수정</button>
            </>:
            <button type="button" className="btn btn-success font_6" onClick={()=>onCreateAnswer()}>등록</button>}
          </div>
        </div> : <div className='answer' style={{marginTop: '20px'}}>
          <h4>답변내용</h4>
          <div dangerouslySetInnerHTML={{__html: sanitizer(content)}}></div>
        </div>}
      </section>}
    </>
  );
}

export default QuestionView;
