import { SET_IS_SHOW_MODAL_BACKDROP, SET_IS_MENU_SIDEBAR_COLLAPSED, SET_SCREEN_SIZE } from '@/actions/types';

const initialState = {
  isShowModalBackdrop: false,
  isMenuSidebarCollapsed: false,
  menuItemFlat: false,
  menuChildIndent: true,
};

export default function ui(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_SHOW_MODAL_BACKDROP:
      return { 
        ...state,
        isShowModalBackdrop: payload
      }
    case SET_IS_MENU_SIDEBAR_COLLAPSED:
      return { 
        ...state,
        isMenuSidebarCollapsed: payload
      }
    case SET_SCREEN_SIZE:
      return { 
        ...state,
        screenSize: payload
      }
    default:
      return state;
  }
}
