import { SET_ACCESS_TOKEN } from '@/actions/types';

let initialState = {
  accessToken: ''
}

export default function account(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ACCESS_TOKEN:
      return {
        accessToken: payload
      }
    default:
      return state;
  }
}
