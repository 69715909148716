import { ADD_TO_CART, REMOVE_ONE_FROM_CART, REMOVE_FROM_CART, CLEAR_CART } from '@/actions/types';

export const addItem = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: {
      cartItem: product
    },
  });
};

export const removeOneItem = (cartItemId) => (dispatch) => {
  dispatch({
    type: REMOVE_ONE_FROM_CART,
    payload: { cartItemId }
  });
};

export const removeItem = (cartItemId) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: { cartItemId }
  });
};

export const clear = () => (dispatch) => {
  dispatch({
    type: CLEAR_CART
  });
};