import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicRefs from 'use-dynamic-refs';
import _ from 'lodash';
import history from '@/helpers/history';
import { toast } from 'react-toastify';

import { leaflet } from '@/actions/leaflet';
import { setIsLoading } from '@/actions/common';

function Home() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const params = useParams();

  const [currentItem, setCurrentItem] = useState(null);

  const { isLoading } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(leaflet(params.leaflet_id))
    .then((res) => {
      // console.log('res',res)
      setCurrentItem(res);
      dispatch(setIsLoading(true));
    })
    .catch(message => {
      // history.navigate('/manager/leaflet/list', {replace: true})
    });
  }, []);

  const scrollToTop = () => {
    getRef('top-screen').current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToBottom = () => {
    getRef('bottom-screen').current.scrollIntoView({ behavior: 'smooth' })
  }

  const clipboard = (string) => {
    navigator.clipboard.writeText(string);
    toast.success('복사했습니다.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 1000,
      hideProgressBar: true
    });
  }
  
  return (
    <>
      <div className='container home'>
        <header className="gnb">
          <div className='gnb_container d-flex justify-content-center align-items-center'>
            <img src="/images/logo.png" alt="마트샵 메인" className="logo_img"/>
          </div>
        </header>
        {currentItem && <>
          <section className='body_container home'>
              {currentItem.sections.map((item,i)=>(
                <div key={item.id}>
                {
                  item.type === 'BannerImage' ? (<div className={i !== 0 ? 'mt-3' : ''}>
                    {
                      item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/placeholder-banner.png`} width='100%'/>)
                    }
                  </div>) : item.type==='ImageProductItem' ? (<>
                    <div className='row mt-3'>                                  
                      {
                        item.title_option === 'image' ? (
                          <div className='col-12'>
                            {item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/placeholder-banner.png`} width='100%'/>)}
                          </div>
                        ) : (
                          <div className='section_title'>{currentItem.sections[i].title_text || '새코너'}</div>
                        )
                      }
                    </div>
                    <div className='row mt-3'>
                      { 
                        currentItem.sections[i].products.map((product, p_index)=>(
                          <div className={`col-${12 / currentItem.sections[i].product_item_column_count}`} key={product.id}>
                            <ul>
                              <li>
                                  <div className='product_text'>
                                    <div className='square border mb-2 product_img'>
                                      <div className='inner'>
                                        <img src={product.image} className='mb-2' onError={e=>e.target.src='/images/no-image.jpg'} style={{objectFit: 'cover'}}/>
                                      </div>
                                      {/* {
                                        product.normal_price > 0 && product.price > 0  && (<div className='ribbon-wrapper'>
                                          <div className='ribbon bg-red'>
                                            {`${100 - Math.round(product.price / product.normal_price * 100)}%`}
                                          </div>
                                        </div>)
                                      } */}
                                    </div>
                                    <p className='tx1'>{product.name || '새상품'}</p>
                                    {product.spec && (<p className='tx2'>{product.spec}</p>)}
                                    <div className='p_discount'>
                                      {product.in_stock ? (
                                        <p>
                                          {product.normal_price>0 && <span className='tx5'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}&nbsp;&nbsp;</span>}<span className='tx3'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span><span className='t1'></span>
                                        </p>
                                      ) : (<p className='tx4'>재고없음</p>)}
                                    </div>
                                  </div>
                              </li>
                            </ul>
                          </div>
                        ))
                      }
                  </div>
                  </>) : (<>
                    <div className='row'>                                  
                      <div className='col-12  mt-3'>
                        { item.title_image ? <img src={`${item.title_image}`} width='100%'/> : <div className='section_title'>{currentItem.sections[i].title_text || '새코너'}</div>}
                      </div>
                    </div>
                    <div className='col-12 text_product_list_wrap mt-3'>
                    { 
                      currentItem.sections[i].products.map(product=>(  
                      <div className='text_product_list_item'>
                        <div className='text_group'>
                          <span className='name'>{product.name || '새상품'}</span>   
                          <span className='spec'>{product.spec}</span> 
                        </div>
                        <div className='price_group'>
                          {product.normal_price>0 && <span className='price1'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                          {product.in_stock ? <span className='price2'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : <span className='outstock'>재고없음</span>}               
                          <button type='button' className='btn btn-primary'>구매</button>
                        </div>
                      </div>
                        ))}
                    </div>
                  </>)
                }
              </div>
            ))}
          </section>
          {/* <div className='footer_wrap home mt-3' style={{paddingBottom: '110px'}}>
            <div className="footer_top_area">
              <a href="/bbs/term.php?gubun=terms">서비스 이용약관</a>
              &nbsp;&nbsp;<span>l</span>&nbsp;&nbsp;
              <a href="/bbs/term.php?gubun=privacy">개인정보 처리방침 및 수집 이용동의</a>
            </div>
              상호: (주)칼라애드 &nbsp;│&nbsp; 주소: 광주광역시 동구 학소로 139, 3층(학동)<br />
              대표: 조원주 &nbsp;│&nbsp; 사업자등록번호: 369-88-01037 &nbsp;│&nbsp; Tel. 062-228-9200<br />
              개인정보 보호책임자: 김세진   
          </div> */}
        </>}
        <div className="gnb_bottom">
          <ul>
            <li>
              <a href="https://dev.martshop.co.kr/shop/">
                <div className="iconN"><i className="fas fa-home"></i></div>
                <p className="home_btn_text">홈</p>
              </a>
            </li>
            <li>
              <a href="tel:062-228-9200">
                <div className="iconN"><i className="fas fa-phone-alt"></i></div>
                <p>전화</p>
              </a>
            </li>
            <li>
              <a href="https://dev.martshop.co.kr/shop/orderinquiry.php">
                <div className="iconN"><i className="fas fa-shopping-cart"></i></div>
                <p>장바구니</p>
              </a>
            </li>
            <li>
              <a href="https://dev.martshop.co.kr/shop/search.php">
                <div className="iconN"><i className="fas fa-store"></i></div>
                <p>매장정보</p>
              </a>
            </li>
            <li>
              <a href="https://dev.martshop.co.kr/shop/mypage.php">
                <div className="iconN"><i className="fas fa-share-alt"></i></div>
                <p>공유</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Home;
