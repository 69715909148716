import api from '@/services/api';
import queryString from 'query-string';

class JBMJService {
  updateAccountApiKey(user_id, params) {
    return api.put(`/accounts/${user_id}/jbmj`, params)
  }

  getAccountInfo(user_id) {
    return api.get(`/accounts/${user_id}/jbmj`)
  }

  getMessages(user_id, params) {
    return api.get(`/accounts/${user_id}/jbmj/messages?${queryString.stringify(params)}`)
  }

  createMessage(user_id, params) {
    return api.post(`/accounts/${user_id}/jbmj/messages`, params)
  }

  cancelMessage(user_id, message_id) {
    return api.delete(`/accounts/${user_id}/jbmj/messages/${message_id}`)
  }

  getGroups(user_id) {
    return api.get(`/accounts/${user_id}/jbmj/groups`)
  }
}

export default new JBMJService();
