import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '@/screens/Layout';
import Login from '@/screens/Login';
import Register from '@/screens/Register';

import NotFound from '@/screens/NotFound';

import PrivateRoute from '@/components/PrivateRoute'
import AdminRoute from '@/components/AdminRoute'
// import Signup from '@/screens/Account/Signup';
// import MyPage from '@/screens/Account/My';

import Home from '@/screens/Home';

import Manager from '@/screens/Manager';
import ManagerDashboard from '@/screens/Manager/Dashboard';

import ManagerLeafletList from '@/screens/Manager/Leaflet/List';
import ManagerLeafletCreate from '@/screens/Manager/Leaflet/Create';
import ManagerLeafletEdit from '@/screens/Manager/Leaflet/Edit';

import ManagerMessageCreate from '@/screens/Manager/Message/Create';
import ManagerMessageList from '@/screens/Manager/Message/List';

import ManagerOrderList from '@/screens/Manager/Order/List';
import ManagerOrderMap from '@/screens/Manager/Order/Map';

import ManagerNoticeList from '@/screens/Manager/Notice/List';
import ManagerNoticeWrite from '@/screens/Manager/Notice/Write';
import ManagerNoticeView from '@/screens/Manager/Notice/View';
import ManagerNoticeEdit from '@/screens/Manager/Notice/Edit';

import ManagerQuestionList from '@/screens/Manager/Question/List';
import ManagerQuestionWrite from '@/screens/Manager/Question/Write';
import ManagerQuestionView from '@/screens/Manager/Question/View';
import ManagerQuestionEdit from '@/screens/Manager/Question/Edit';

import ManagerAccountSetting from '@/screens/Manager/AccountSetting';

import Leaflet from '@/screens/Leaflet';
import LeafletCart from '@/screens/Leaflet/Cart';
import LeafletOrder from '@/screens/Leaflet/Order';
import LeafletShopInfo from '@/screens/Leaflet/ShopInfo';
import LeafletHome from '@/screens/Leaflet/Home';

import OrderConfirm from '@/screens/Order/Confirm';

function Router() {
  const currentUser = useSelector(state => state.user);
  const { items } = useSelector((state) => state.cart);
  const parameters = new URLSearchParams(window.location.search);
  
  let element = useRoutes([
    { path: '/',
      element: <Layout />,
      children: [
        { index: true, element: currentUser?.isLoggedIn ? <Navigate to='/manager' /> : <Login /> },
        { path: 'register', element: currentUser?.isLoggedIn ? <Navigate to='/manager' /> : <Register /> },
        // { path: 'home/:leaflet_id', element: <Home /> },
        { path: 'orders/:order_id', element: <OrderConfirm /> },
        { path: 'shop', 
          element: <Leaflet />,
          children: [
            { path: 'cart/:leaflet_id', element: <LeafletCart />},
            { path: 'cart/:leaflet_id/order', element: <LeafletOrder />},
            { path: 'cart/:leaflet_id/order/:order_id', element: <OrderConfirm /> },
            { path: 'info/:leaflet_id', element: <LeafletShopInfo />},
            { path: ':leaflet_id', element: <LeafletHome />},
          ]
        },
      ]
    }, 
    { path: 'manager',
      element: <PrivateRoute><Manager /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to={'/manager/dashboard'}  /> },
        { path: 'dashboard', element: <ManagerDashboard />},
        { path: 'settings', element: <ManagerAccountSetting />},
        { path: 'leaflet/list', element: <ManagerLeafletList />},      
        { path: 'leaflet/create', element: <ManagerLeafletCreate />},      
        { path: 'leaflet/:leaflet_id/modify', element: <ManagerLeafletEdit />},      
        { path: 'message/create', element: <ManagerMessageCreate />},     
        { path: 'message/list', element: <ManagerMessageList />},      
        { path: 'support/noti/list', element: <ManagerNoticeList />},      
        { path: 'support/noti/write', element: <AdminRoute><ManagerNoticeWrite /></AdminRoute>},      
        { path: 'support/noti/view/:notice_id', element: <ManagerNoticeView />}, 
        { path: 'support/noti/edit/:notice_id', element: <AdminRoute><ManagerNoticeEdit /></AdminRoute>},      
        { path: 'support/qa/list', element: <ManagerQuestionList />},      
        { path: 'support/qa/write', element: <ManagerQuestionWrite />},      
        { path: 'support/qa/view/:question_id', element: <ManagerQuestionView />},      
        { path: 'support/qa/edit/:question_id', element: <ManagerQuestionEdit />},      
        { path: 'order/list', element: <ManagerOrderList />},
        { path: 'order/map', element: <ManagerOrderMap />},
      ]
    },
    { path: 'not-found', element: <NotFound /> },
    { path: '*', element: <Navigate to='/not-found' /> }
  ]);

  return element;
}

export default Router;
