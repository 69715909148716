import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Footer';
import { addItem } from '@/actions/cart';
import { toast } from 'react-toastify';

import history from '@/helpers/history';

export default function Home() {
  const dispatch = useDispatch();
  const params = useParams();

  const { leaflet } = useSelector((state) => state.leaflet);

  return (
    <>
      {leaflet && <>
        <section className=''>
            {leaflet.sections.map((item,i)=>(
                <div key={item.id}>
                  {
                    item.type === 'BannerImage' ? (<div className={i !== 0 ? 'mt-1' : ''}>
                      {
                        item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/placeholder-banner.png`} width='100%'/>)
                      }
                    </div>) : item.type==='ImageProductItem' ? (<>
                      <div className='row mt-1'>                                  
                        {
                          item.title_option === 'image' ? (
                            <div className='col-12'>
                              {item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/placeholder-banner.png`} width='100%'/>)}
                            </div>
                          ) : (
                            item.title_option === 'text' && <div className='section_title'>{leaflet.sections[i].title_text || '새코너'}</div>
                          )
                        }
                      </div>
                      <div className='row mt-1'>
                        { 
                          item.products.map(product=>(
                            <div className={`col-${12 / item.product_item_column_count} product_text`} key={product.id}>
                              <div className='postion-relative square border mb-2 product_img'>
                                <div className='inner'>
                                  {product.image ? <img src={product.image} className='mb-2' onError={e=>e.target.src='/images/no-image.jpg'} style={{objectFit: 'cover'}}/> : <img src={'/images/no-image.jpg'} className='mb-2' style={{objectFit: 'cover'}}/>}
                                </div>
                                {/* {
                                  product.normal_price > 0 && product.price > 0  && (<div className='ribbon-wrapper'>
                                    <div className='ribbon bg-red'>
                                      {`${100 - Math.round(product.price / product.normal_price * 100)}%`}
                                    </div>
                                  </div>)
                                } */}
                                {leaflet.isOnSale && product.in_stock && <button className='position-absolute btn btn-primary' style={{bottom:'0', right:'0'}} onClick={()=>{
                                  dispatch(addItem(product))
                                  toast.success('장바구니에 담았습니다.', {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    autoClose: 1000,
                                    hideProgressBar: true
                                  });
                                }}><i className="fas fa-cart-plus"></i></button>}
                              </div>
                              <p className='tx1'>{product.name || '새상품'}</p>
                              {product.spec && (<p className='tx2'>{product.spec}</p>)}
                              <div className='p_discount'>
                                {product.in_stock ? (
                                  <p>
                                    {product.normal_price>0 && <span className='tx5'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}&nbsp;&nbsp;</span>}<span className='tx3'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span><span className='t1'></span>
                                  </p>
                                ) : (<p className='tx4'>재고없음</p>)}
                              </div>
                            </div>
                          ))
                        }
                    </div>
                    </>) : (<>
                      <div className='row'>                                  
                        {
                          item.title_option === 'image' ? (
                            <div className='col-12'>
                              {item.title_image ? (<img src={`${item.title_image}`} className='w-100'/>) : (<img src={`/images/placeholder-banner.png`} width='100%'/>)}
                            </div>
                          ) : (
                            item.title_option === 'text' && <div className='section_title'>{leaflet.sections[i].title_text || '새코너'}</div>
                          )
                        }
                      </div>
                      <div className='col-12 text_product_list_wrap mt-1'>
                      { 
                        item.products.map(product=>(  
                        <div className='text_product_list_item'>
                          <div className='text_group'>
                            <span className='name'>{product.name || '새상품'}</span>   
                            <span className='spec'>{product.spec}</span> 
                          </div>
                          <div className='price_group'>
                            {product.normal_price>0 && <span className='price1'>{product.normal_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                            {product.in_stock ? <span className='price2'>{product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : <span className='outstock'>재고없음</span>}               
                          </div>
                          {leaflet.isOnSale && product.in_stock && <button type='button' className='btn btn-primary ml-1' onClick={()=>{
                                  dispatch(addItem(product))
                                  toast.success('장바구니에 담았습니다.', {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    autoClose: 1000,
                                    hideProgressBar: true
                                  });
                                }}><i className="fas fa-cart-plus"></i></button>}
                        </div>
                          ))}
                      </div>
                    </>)
               }
            </div>
           ))}

        <Footer />
        </section>
      </>}
    </>
  );
}
