import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentHeader from '@/components/ContentHeader';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useDynamicRefs from 'use-dynamic-refs';
import config from '@/config';
import _ from 'lodash';
import history from '@/helpers/history';
import randomBytes from 'randombytes';
import { toast } from 'react-toastify';
import Moment from 'react-moment';
import moment from 'moment';

import { getAccountInfo, getGroups, createMessage } from '@/actions/jbmj';
import { leaflets, leaflet } from '@/actions/leaflet';

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko)

function MessageCreate() {
  const dispatch = useDispatch();
  const [getRef, setRef] =  useDynamicRefs();

  const currentUser = useSelector(state => state.user);

  const [checkList, setCheckList] = useState([]);
  const [idList, setIdList] = useState([]);
  
  const [JBMJAccountInfo, setJBMJAccountInfo] = useState(null);
  const [groups, setGroups] = useState(null);
  const [leafletItems, setLeafletItems] = useState(null);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    message_subject: Yup.string().required('발송 제목을 입력해 주세요.'),
    message_text: Yup.string().required('발송 내용을 입력해 주세요.'),
  });

  const formOptions = {
    defaultValues: {
      _id: randomBytes(12).toString('hex'),
      message_subject: '',
      message_subject_ad: true,
      message_text: '',
      message_url: '',
      customer_number_source: 'group',
      send_type: '1',
      customer_numbers: [],
      group_nos: [],
      send_number: '',
      reject_number: '',
      reserve_send_date: moment().format('YYYY-MM-DD'),
      reserve_send_hour: '08',
      reserve_send_minute: '00'
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, control, handleSubmit, setValue, setError, reset, formState, watch } = useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({ name: 'customer_numbers', control });

  const formData = watch();
  
  useEffect(() => {
    dispatch(getAccountInfo(
      currentUser?.user?.id,
    ))
    .then(res => {
      setJBMJAccountInfo(res)
      
      if(res.send_numbers[0]){
        setValue('send_number', res.send_numbers[0])
      }
      
      if(res.reject_numbers[0]){
        setValue('reject_number', res.reject_numbers[0])  
      }
    })

    dispatch(getGroups(
      currentUser?.user?.id,
    ))
    .then(res => {
      setIdList(res.item_list.map(item=>item.no))
      setGroups(res)
    })

    dispatch(leaflets({
      page: 1,
      limit: 20
    }))
    .then(res => {
      setLeafletItems(res.docs)

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let leaflet_id = params.get('leaflet_id');
      
      if(leaflet_id){
        console.log('window.location.origin', window.location.origin)
        setValue('message_url', `${window.location.origin}/shop/${leaflet_id}`)
      }
    })
  }, []);

  const getLeaflet = () => {
    dispatch(leaflet(formData.message_url.split('/shop/')[1]))
    .then(res => {
      const sessions = _.filter(res.sections, row=>row.type !== 'BannerImage');
      let new_message_text = '';
      
      for (let index = 0; index < sessions.length; index++) {
        new_message_text += sessions[index].products.map(product=>`${product.name} ${product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}\n`).join('');
      }

      setValue('message_text', new_message_text.substring(0, new_message_text.lastIndexOf('\n')))
    })
  }

  const onChangeEach = (e, id) => {
    if (e.target.checked) {
      setCheckList([...checkList, id]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== id));
    }
  }

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onSubmit = (data) => {
    // const test 
    console.log('data', data)
    
    if(data.message_subject_ad){
      data.message_subject = `(광고)${data.message_subject}`
    }

    if(data.message_url){
      data.message_text += `\n행사보기\n${data.message_url}`
    }

    if(data.reject_number){
      data.message_text += `\n수신거부 : ${data.reject_number}`
    }

    const messageData = {
      send_number: data.send_number,
      message_type: getTextLength(data.message_text.concat(data.message_url?`\n행사보기\n${data.message_url}`:''))>80 ? 'LMS': 'SMS',
      reject_number: data.reject_number,
      message_subject: data.message_subject,
      message_text: data.message_text,
      send_type: data.send_type,
      reserve_send_date: data.reserve_send_date,
      reserve_send_hour: data.reserve_send_hour,
      reserve_send_minute: data.reserve_send_minute,
      customer_number_source: data.customer_number_source,
      g_nos_with_comma: data.customer_number_source === 'group' ? data.group_nos.join(',') : '',
      customer_numbers_with_comma: data.customer_number_source === 'number' ? data.customer_numbers.map(item=>item.number).join(',') : ''
    }
    
    dispatch(createMessage(currentUser?.user?.id,messageData))
    .then((res) => {
      toast.success('메시지를 발송했습니다.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      history.navigate(`/manager/message/list`, {replace: true})
    })
    .catch(message => {
      console.log('message')
    })
  }

  const getTextLength = (str) => {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
      if (escape(str.charAt(i)).length == 6) {
          len++;
      }
      len++;
    }
    return len;
  }
  
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <section className='content container_create'>
          <div className='container'>
            <div className='row col-fixed'>
              <div className='col-w1'>
                  <div className='new_box_s'>
                    <div className='card-body'>
                    <div className="box_title font_7">
                      메시지 발송
                      <div className="send_title">
                        <label className='col-2 ml-2 mt-1 check_c'><input type="checkbox" {...register(`message_subject_ad`)} className="chk-default"/><span>광고</span></label>
                      </div>
                    </div>
                      <div className={`form-group ${errors.message_subject ? 'is-invalid' : ''}`}>
                        <label htmlFor='message_subject'>메시지 제목</label>
                        <div className='row'>
                          <input type='text' className='form-control col' id={`message_subject`} name={`message_subject`} {...register(`message_subject`)} placeholder='제목을 입력해 주세요.' />
                        </div>
                        <div className='invalid-feedback'>{errors.message_subject?.message}</div>
                      </div>
                      <div className={`form-group ${errors.message_text ? 'is-invalid' : ''}`}>
                        <div className='row'>
                          <label htmlFor='message_text' className='col'>발송 내용 입력</label>
                          <div className='col text-right'>{getTextLength(formData.message_text.concat(formData.message_url?`\n행사보기\n${formData.message_url}`:''))>80 ? 'LMS': 'SMS'} &nbsp; <span style={{color: '#e1124c'}}>{getTextLength(formData.message_text.concat(formData.message_url?`\n행사보기\n${formData.message_url}`:''))}</span> / <span style={{color: '#e1124c'}}>{getTextLength(formData.message_text.concat(formData.message_url?`\n행사보기\n${formData.message_url}`:''))>80 ? 2000: 80}</span></div>  
                        </div>
                        <textarea className='form-control' rows={15} name={`message_text`} {...register(`message_text`)} placeholder='발송내용을 입력해 주세요.'></textarea>
                        <div className='invalid-feedback'>{errors.message_text?.message}</div>
                      </div>
                      <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                        <label htmlFor='title'>디지털 전단</label>
                        <div className='row box_Send'>
                          <select className='form-control' {...register(`message_url`)}>
                            <option value=''>발송전단선택</option>
                            {
                              leafletItems && leafletItems.map(leaflet=><option value={`${window.location.origin}/shop/${leaflet.id}`} selected={formData.message_url === `${window.location.origin}/shop/${leaflet.id}`}>{leaflet.title} / <Moment format='YYYY-MM-DD'>{leaflet.created}</Moment></option>)
                            }
                          </select>
                          {formData.message_url && <>
                            <a href={formData.message_url} target='_blank'>
                              <button type='button' className='btn btn_blue btn_ssok ml-1'>확 인</button>
                            </a>
                            <button type='button' className='btn btn_black_Fw ml-1' onClick={getLeaflet}>가져오기</button>
                          </>}
                        </div>
                      </div>
                      {
                      JBMJAccountInfo &&
                      <>
                        {
                          JBMJAccountInfo.send_numbers.length > 0 && <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                          <label htmlFor='title'>발신번호</label>
                          <div className='row'>
                            <select className='form-control col-6 ml-2' {...register(`send_number`)}>
                              {
                                JBMJAccountInfo.send_numbers.map(number=><option value={number}>{number}</option>)
                              }
                            </select>
                          </div>
                        </div>
                        }
                        {JBMJAccountInfo.reject_numbers.length > 0 && <div className={`form-group ${errors.title ? 'is-invalid' : ''}`}>
                          <label htmlFor='title'>수신거부</label>
                          <div className='row'>
                            <select className='form-control col-6 ml-2' {...register(`reject_number`)}>
                              {
                                JBMJAccountInfo.reject_numbers.map(number=><option value={number}>{number}</option>)
                              }
                            </select>
                          </div>
                        </div>}
                      </>
                      }
                    </div>
                  </div>
              </div>
              <div className='col-w2'>
                <div className='new_box_s sticky-top' >
                  <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <h3 className='card-title' style={{lineHeight: '21.25px'}}>미리보기</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-body' >
                    <div className='phone_g'>
                        <div className='phone_ft'>
                          <div className='phone_message_bg'>
                            <div className='phone_message_con'>
                              <div className='mass_text_box'>
                                <div className='mass_text1'></div>
                                  {/* <div id='preview_LMSfile_box' className='mass_text2 fc_b ftw_5' style={{display:'block'}}>
                                    <div style={{display: 'inline-block', position: 'relative'}}>
                                      <img className='thumb' id='preview_LMSfile' src='<?=$message_info['LMSfile_path']?>' title='1'/>
                                      <button type='button' className='btn' id='btn-unselect-LMSfile' style={{position: 'absolute', top: '0', right: '0', fontSize:'25px',cursor:'pointer',zIndex: '1'}}></button>
                                    </div>
                                  </div> */}
                                  {formData.message_subject && <div id='preview_input_subject' className='mass_text2 fc_b ftw_5' style={{wordBreak:'break-all',fontWeight:'bold'}}>
                                    {formData.message_subject_ad && <>(광고)</>}{formData.message_subject}
                                  </div>}
                                  <div id='preview_input_message' className='mass_text2 fc_b ftw_5' style={{wordBreak:'break-all'}}>
                                    {formData.message_text.length > 0 && formData.message_text.split('\n').map(line=><>{line}<br/></>)}
                                    {
                                    formData.message_url && <>
                                      {formData.message_text.length > 0 && <br />}
                                      행사보기<br />
                                      {formData.message_url}
                                    </>
                                    }
                                  </div>
                                  {formData.reject_number && <div id='preview_reject_number_message' className='mass_text2 fc_b ftw_5' style={{wordBreak:'break-all'}}>
                                    <br />
                                    수신거부 : {formData.reject_number}
                                  </div>}
                                  <div className='mass_text3'></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-w3">
                <div className='new_box_s'>
                  <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <h3 className='card-title' style={{lineHeight: '21.25px'}}>수신고객</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='form-group'>
                      <label className='radio-inline check_c'><input type='radio' name='customer_number_source' value='group' {...register(`customer_number_source`)} checked={formData.customer_number_source === 'group'} className="chk-default"/><span>그룹 선택</span></label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <label className='radio-inline check_c'><input type='radio' name='customer_number_source' value='number' {...register(`customer_number_source`)} checked={formData.customer_number_source === 'number'} className="chk-default"/><span>연락처 입력</span></label>
                    </div>
                    {
                      formData.customer_number_source === 'group' ?
                        <table className='table table-bordered text-center table-hover'>
                          <colgroup>
                              <col width='50px'/>
                              <col/>
                              <col width='100px'/>
                          </colgroup>
                          <thead className='thead-light'>
                            <tr>
                              <th>
                                <label className='check_c'>
                                <input type='checkbox' onChange={onChangeAll} checked={checkList.length === idList.length} className="chk-default"/>
                                <span></span></label>
                              </th>
                              <th className='align-middle'>그룹명</th>
                              <th className='align-middle'>수신자(명)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groups && groups.item_list &&  groups.item_list.map(group=><tr>
                              <td className="">
                                <label className='check_c'>
                                  <input type='checkbox' {...register("group_nos")} value={group.no} onChange={(e) => onChangeEach(e, group.no)} checked={checkList.includes(group.no)} className="chk-default"/>
                                  <span></span>
                                </label>
                              </td>
                              <td className='align-leftx'>{group.name}</td>
                              <td className='align-middle'>{group.member_count}</td>
                            </tr>)}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th className='total_cont_x2' colSpan={3}>
                                총 {groups ? _.sumBy(_.filter(groups.item_list || [], item => checkList.includes(item.no)), item => Number(item.member_count)) : 0}건
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      :
                        <table className='table table-style22'>
                          <colgroup>
                            <col/>
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>
                              {fields.map((field, idx)=><div className="btn_field_number">
                                  <button type='button' className='btn btn-close' onClick={()=>remove(idx)}></button> {field.number}
                              </div>)}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot className='thead-light'>
                            <tr>
                              <td className='text_number'>
                                <div className='row'>
                                  <input type='tel' pattern="/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/" className='form-control col-8' ref={setRef(`customer_number_source_number_input`)} placeholder='010-0000-0000' />
                                  <button type='button' className='btn btn-primary col ml-2' onClick={()=>{
                                    let regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
                                    if (regPhone.test(getRef('customer_number_source_number_input').current.value) === true) {
                                        append({number: getRef('customer_number_source_number_input').current.value})
                                    } else {
                                      alert('휴대폰번호 형식이 아닙니다.');
                                    }
                                    getRef('customer_number_source_number_input').current.value = ''
                                  }}>번호추가</button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th className='total_cont_x2'>
                                총 {fields.length}건
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                    }
                  </div>
                </div>

                <div className='new_box_s'>
                  <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>
                        <h3 className='card-title' style={{lineHeight: '21.25px'}}>발송구분</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='check_c'>
                      <label className='radio-inline'><input type='radio' name='send_type' value='1' {...register(`send_type`)} checked={formData.send_type == '1'} className="chk-default"/><span>즉시</span></label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <label className='radio-inline'><input type='radio' name='send_type' value='2' {...register(`send_type`)} checked={formData.send_type == '2'}  className="chk-default"/><span>예약</span></label>
                    </div>
                    {formData.send_type == 1 &&<div className="current_time">
                      <span className="inner_1"><Moment format='YYYY년 MM월 DD일'>{new Date()}</Moment></span>
                      <span className="inner_2"><Moment format='HH:mm:ss'>{new Date()}</Moment></span>
                    </div>}

                    {formData.send_type == '2' && <div className=''>
                    <div className="dayy_text">예약 발송일 지정</div>
                    <div className='row current_dayy'>
                      <Controller
                        control={control}
                        name="reserve_send_date"
                        render={({ field }) => (
                          <>
                            <DatePicker
                              minDate={new Date()}
                              {...field}
                              dateFormat="yyyy-MM-dd"
                              placeholderText="예약시작일"
                              isClearable
                              locale={'ko'}
                              className='form-control col-2 mr-1' 
                              inline={true}
                            />
                          </>
                        )}
                      />
                      <div className="right_sss">
                        <div className="inner_t1">
                            <p>시간</p>
                            <select className='form-control col-2' {...register(`reserve_send_hour`)} onChange={e=>{
                              setValue('reserve_send_minute', '00')
                            }}>
                              <option value='08' selected={formData.reserve_send_hour === '08'}>08</option>
                              <option value='09' selected={formData.reserve_send_hour === '09'}>09</option>
                              <option value='10' selected={formData.reserve_send_hour === '10'}>10</option>
                              <option value='11' selected={formData.reserve_send_hour === '11'}>11</option>
                              <option value='12' selected={formData.reserve_send_hour === '12'}>12</option>
                              <option value='13' selected={formData.reserve_send_hour === '13'}>13</option>
                              <option value='14' selected={formData.reserve_send_hour === '14'}>14</option>
                              <option value='15' selected={formData.reserve_send_hour === '15'}>15</option>
                              <option value='16' selected={formData.reserve_send_hour === '16'}>16</option>
                              <option value='17' selected={formData.reserve_send_hour === '17'}>17</option>
                              <option value='18' selected={formData.reserve_send_hour === '18'}>18</option>
                            </select>
                        </div>
                        <div className="inner_t2">
                            <p>분</p>
                            <select className='form-control  col-2' {...register(`reserve_send_minute`)}>
                              <option value='00' selected={formData.reserve_send_minute === '00'}>00</option>
                              <option value='05' selected={formData.reserve_send_minute === '05'}>05</option>
                              <option value='15' selected={formData.reserve_send_minute === '15'}>15</option>
                              <option value='20' selected={formData.reserve_send_minute === '20'}>20</option>
                              <option value='25' selected={formData.reserve_send_minute === '25'}>25</option>
                              <option value='30' selected={formData.reserve_send_minute === '30'}>30</option>
                              <option value='35' selected={formData.reserve_send_minute === '35'}>35</option>
                              <option value='40' selected={formData.reserve_send_minute === '40'}>40</option>
                              <option value='45' selected={formData.reserve_send_minute === '45'}>45</option>
                              <option value='50' selected={formData.reserve_send_minute === '50'}>50</option>
                              <option value='55' selected={formData.reserve_send_minute === '55'}>55</option>
                            </select>
                        </div>
                      </div>
                      </div>
                    </div>}
                  </div>
                  <div className='send_box_cc'>
                    <button type='submit' className='btn btn-primary' disabled={formData.message_subject.length === 0 || formData.message_text.length === 0 || formData.send_number.length === 0 || (formData.customer_number_source === 'group' && checkList.length === 0) || (formData.customer_number_source === 'number' && fields.length === 0)}>메시지 발송</button>
                  </div>

                </div>

              </div>


          </div>
        </div>
      </section>
      </form>
    </>
  );
}

export default MessageCreate;
