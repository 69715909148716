import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DaumPostcode from 'react-daum-postcode';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import randomBytes from 'randombytes';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import history from '@/helpers/history';

import { clear } from '@/actions/cart';
import { createOrder } from '@/actions/order';

export default function Order() {
  const dispatch = useDispatch();
  const params = useParams();

  const { leaflet } = useSelector((state) => state.leaflet);

  const validationSchema = Yup.object().shape({
    pay_method: Yup.string().required('결제방법을 선택해 주세요.'),
    name: Yup.string().required('이름을 입력해 주세요.'),
    mobile: Yup.string().required('휴대폰 번호를 입력해 주세요'),
    address1: Yup.string().required('배송지를 입력해 주세요.'),
    address2: Yup.string(),
    memo: Yup.string(),
  });

  const formOptions = {
    defaultValues: {
      leaflet: params.leaflet_id,
      order_no: randomBytes(12).toString('hex'),
      pay_method: 'card'
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const { items } = useSelector((state) => state.cart);
  
  const formData = watch();

  console.log('formData', formData)
  console.log('errors', errors)

  useEffect(() => {
    if(items.length === 0){
      history.navigate(`/shop/${params.leaflet_id}`, { replace: true })
    }
  });
  
  const cartQty = items
    .map((item) => item.qty)
    .reduce((prev, current) => prev + current, 0);
  const cartTotal = items
    .map((item) => item.price * item.qty)
    .reduce((prev, current) => prev + current, 0);

  const [openPostcode, setOpenPostcode] = useState(false);

  const handleAddress = {
    // 버튼 클릭 이벤트
    clickButton: () => {
        setOpenPostcode(current => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data) => {
      setOpenPostcode(false);
      setValue('address1',data.address)
    },
  }

  const onSubmit = (data) => {
    dispatch(createOrder({
      ...data,
      cart: items
    }))
    .then(res => {
      dispatch(clear());
      history.navigate(`/shop/cart/${params.leaflet_id}/order/${res.id}`, { replace: true })
    })
    .catch(message => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 mt-3 mb-2'>
          <h5>주문서 작성</h5>
        </div>
      </div>
      <div className="row">
      <div className="col-12">
        {items.map(item=><div className="card rounded-3 mb-2">
          <div className="card-body p-2">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-2">
                  {item.image ? <img
                      src={item.image}
                      onError={e=>e.target.src='/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} /> : <img
                      src={'/images/no-image.jpg'}
                      className="img-fluid rounded-3" alt={item.name} />}
              </div>
              <div className="col-5">
                <p className="mb-2 bold ">{item.name}</p>
                <p><span className="text-muted">{item.spec}</span></p>
              </div>
              <div className="col-2 d-flex text-center">
                <h5 className="mb-0">{item.qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
              </div>
              <div className="col-3 text-center">
                <h5 className="mb-0">{(item.qty * item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h5>
              </div>
            </div>
          </div>
        </div>)}

        <div className="card mb-2">
          <div className="card-body">
            <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
              <dt>상품금액</dt>  
              <dd><strong className='d_price'>{cartTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
            </dl>
            <dl className='d-flex justify-content-between align-items-center w-100 mb-1'>
              <dt>배송비</dt>  
              <dd><strong className='d_price'>{
                leaflet?.shop ? cartTotal >= leaflet.shop.delivery_fee_free_amt ? '무료배송' : leaflet.shop.delivery_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','): '무료배송'
              }</strong></dd>
            </dl>
            <dl className='d-flex justify-content-between align-items-center w-100 mb-0'>
              <dt>합계</dt>  
              <dd><strong className='fc_red t_price'>{(cartTotal+(cartTotal >= (leaflet?.shop?.delivery_fee_free_amt || 0) ? 0 : (leaflet?.shop?.delivery_fee || 0))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</strong></dd>
            </dl>
          </div>
        </div>

      </div>
    </div>
    <div className="card">
      <div className="card-body">
      <form  onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='order_no' {...register('order_no')}/>
        <input type='hidden' name='leaflet' {...register('leaflet')}/>
        <div className="form-group">
          <label htmlFor="pay_method">결제방법</label>
          <div className='input-group'>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="pay_method" id="pay_method_card" value="card" {...register('pay_method')} checked={formData.pay_method === 'card'}/>
              <label className="form-check-label" htmlFor="pay_method_card">현장결제(카드)</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="pay_method" id="pay_method_cash" value="cash" {...register('pay_method')} checked={formData.pay_method === 'cash'}/>
              <label className="form-check-label" htmlFor="pay_method_cash">현장결제(현금)</label>
            </div>
          </div>
        </div>
        <div className={`form-group ${errors.name?.message ? 'is-invalid' : ''}`}>
          <label htmlFor="name">이름</label>
          <input type="text" className="form-control" id="name" name="name" {...register('name')} placeholder="홍길동"/>
          <div className='invalid-feedback'>{errors.name?.message}</div>
        </div>  
        <div className={`form-group ${errors.mobile?.message ? 'is-invalid' : ''}`}>
          <label htmlFor="mobile">휴대폰번호</label>
          <input type="text" className="form-control" id="mobile" name="mobile" {...register('mobile',{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} placeholder="010-0000-0000"/>
          <div className='invalid-feedback'>{errors.mobile?.message}</div>
        </div>   
        <div className={`form-group ${errors.address1?.message ? 'is-invalid' : ''}`}>
          <label htmlFor="address1">배송지</label>
          <div className="input-group">
            {!openPostcode && <input type="text" className="form-control" id="address1" name="address1" {...register('address1')} readOnly={!openPostcode}/>}
            {openPostcode && 
              <DaumPostcode 
                  onComplete={handleAddress.selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                  autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                  defaultQuery={''} // 팝업을 열때 기본적으로 입력되는 검색어 
            />}
            {!openPostcode && <div className="input-group-append">
            <button type="button" onClick={handleAddress.clickButton} className="btn btn-primary">주소입력</button>
            </div>}
          </div>
          <div className='invalid-feedback'>{errors.address1?.message}</div>
        </div>
        <div className={`form-group ${errors.address2?.message ? 'is-invalid' : ''}`}>
          <label htmlFor="inputAddress2">상세주소</label>
          <input type="text" className="form-control" id="address2" name="address2" {...register('address2')} placeholder=""/>
          <div className='invalid-feedback'>{errors.address2?.message}</div>
        </div>
        <div className={`form-group ${errors.memo?.message ? 'is-invalid' : ''}`}>
          <label htmlFor="memo">요청사항(선택)</label>
          <input type="text" className="form-control"  id="memo" name="memo" {...register('memo')} placeholder=""/>
          <div className='invalid-feedback'>{errors.memo?.message}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary btn-block btn-lg">주문하기</button>
          </div>
        </div>
      </form>
      </div>
    </div>
    </>
  );
}
